export const getQueryParam = (queryKey: string): string => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(queryKey) ?? "";
};

export const getPvinFromUrl = (): string => {
  const location = window.location;
  const urlParts = location.pathname.split("/");

  return urlParts.at(-1) ?? "";
};

export const toAlphanumericString = (value?: unknown): string => {
  const stringValue = String(value ?? "");
  return stringValue.replace(/[^a-zA-Z0-9]/g, "");
};
