import { Link } from "@tanstack/react-router";
import { ColumnType } from "antd/es/table";

import { AnomalyDetectorType, AnomalyDetectorWithVehicleData } from "@/api/customerApi";
import { compareNumber, compareText } from "@/components/tables/sorting";
import { formatNumber } from "@/utils/numberUtils";

import { fromAnomaliesTableStateValue } from "./AnomalySummaryTable";

export const anomalySummaryCommonColumns: ColumnType<AnomalyDetectorWithVehicleData>[] = [
  {
    key: "name",
    title: "Name",
    dataIndex: "detector.name",
    sorter: (a, b) => compareText(a.detector?.name, b.detector?.name),
    render: (_text: string, row) => {
      if (typeof row === "object" && row !== null && row?.detector?.id) {
        //TODO: Use jeremy's universal timeseries source id instead?
        const to = `/anomaly-detector/${
          row.detector.type == AnomalyDetectorType.Pattern ? row.detector.id : `ml-${row.detector.id}`
        }`;
        return (
          <Link to={to} state={{ __tempKey: fromAnomaliesTableStateValue }}>
            {row.detector?.name}
          </Link>
        );
      }
    },
    width: 250,
  },
  {
    key: "detectorDescription",
    title: "Description",
    dataIndex: "detector.description",
    sorter: (a, b) => compareText(a.detector?.description ?? "", b.detector?.description ?? ""),
    render: (_text, row) => {
      if (typeof row === "object" && row !== null && row?.detector?.id) {
        return row?.detector?.description ?? "-";
      }
    },
  },
  {
    key: "anomalyVehicleCount",
    title: "Current Vehicles",
    dataIndex: "anomalyVehicleCount",
    defaultSortOrder: "descend",
    sorter: (a, b) => compareNumber(a.data?.anomalyVehicleCount ?? 0, b.data?.anomalyVehicleCount ?? 0),
    render: (_text: string, row) => formatNumber(row.data?.anomalyVehicleCount ?? 0),
    width: 160,
  },
];
