import { useSearch } from "@tanstack/react-router";

export type FaultEventAnalysisFilter = {
  ecu?: string;
  troubleCode?: string;
  softwareVersion?: string;
  hardwareVersion?: string;
  softwarePartNumber?: string;
  hardwarePartNumber?: string;
  clusterId?: string;
};

export type FaultEventAnalysisSearchFilter = {
  eventFilter: FaultEventAnalysisFilter;
};

export const useEventAnalysisSearch = (): FaultEventAnalysisFilter =>
  useSearch({
    strict: false,
    select: ({ eventFilter }) => ({
      ...eventFilter,
    }),
  });
