import { Link } from "@tanstack/react-router";
import { SortOrder } from "antd/lib/table/interface";
import capitalize from "lodash/capitalize";
import React from "react";

import { EventType, RepairEventType, useRecentEventsQuery } from "@/api";
import { BasicTable } from "@/components/tables/BasicTable";
import { compareStrTimestamps, compareText } from "@/components/tables/sorting";
import FromNowFormatter from "@/components/ui/FromNowFormatter";
import { getCurrentCustomerId } from "@/utils/customers";

export const RecentlyViewedEventsTable = () => {
  const { data, isLoading } = useRecentEventsQuery({});
  const events =
    data?.userSettings?.customerSettings?.find((customer) => customer.customerId === getCurrentCustomerId())
      ?.recentEvents ?? [];

  type RecentlyViewedEvent = (typeof events)[number];

  const buildEventLink = (event: RecentlyViewedEvent): React.ReactNode => {
    const eventName = getEventName(event);
    switch (event.type) {
      case EventType.Fault:
        return (
          <Link
            to="/fault/$faultCodeId"
            params={{ faultCodeId: event.eventId }}
            search={event.ecu ? { eventFilter: { ecu: event.ecu } } : undefined}
          >
            {eventName}
          </Link>
        );
      case EventType.Expression:
        return (
          <Link to="/pattern-event/$patternEventId" params={{ patternEventId: event.eventId }}>
            {eventName}
          </Link>
        );
      case EventType.Repair:
        if (event.eventId === RepairEventType.BatteryFullReplacement) {
          return <Link to="/repair/full">{eventName}</Link>;
        }
        return <Link to="/repair/partial">{eventName}</Link>;
      default:
        return <Link to="/error">{eventName}</Link>;
    }
  };

  const getEventName = (event: RecentlyViewedEvent): string => {
    return event.name && event.name != "" ? event.name : event.eventId;
  };

  const getEventType = (event: RecentlyViewedEvent): string => {
    return event.type === EventType.Expression ? "Pattern" : capitalize(event.type);
  };

  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      sorter: (a: RecentlyViewedEvent, b: RecentlyViewedEvent) => compareText(getEventName(a), getEventName(b)),
      render: (_: string, row: RecentlyViewedEvent) => {
        return buildEventLink(row);
      },
    },
    {
      key: "ecu",
      title: "ECU",
      dataIndex: "ecu",
      sorter: (a: RecentlyViewedEvent, b: RecentlyViewedEvent) => compareText(a.ecu ?? "", b.ecu ?? ""),
      render: (_: string, row: (typeof events)[number]) => {
        return row.ecu !== "" ? row.ecu : "-";
      },
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
      width: 120,
      sorter: (a: RecentlyViewedEvent, b: RecentlyViewedEvent) => compareText(a.type, b.type),
      render: (_: string, row: (typeof events)[number]) => {
        return getEventType(row);
      },
    },
    {
      key: "lastViewed",
      title: "Last Viewed",
      dataIndex: "timestamp",
      width: 180,
      defaultSortOrder: "descend" as SortOrder,
      sorter: (a: RecentlyViewedEvent, b: RecentlyViewedEvent) => compareStrTimestamps(a.timestamp, b.timestamp),
      render: (_: string, row: (typeof events)[number]) => {
        return <FromNowFormatter value={new Date(row.timestamp)} />;
      },
    },
  ];

  return <BasicTable dataSource={events} loading={isLoading} columns={columns} rowKey="eventId" pagination={false} />;
};
