import { Dispatch, SetStateAction } from "react";

import { Cluster } from "@/api";
import { AffectedVehicleAttributes } from "@/api/customerApi";
import { FaultEventsTable } from "@/components/tables/Cluster/FaultEventsTable";
import { BasicWidget } from "@/components/widget/BasicWidget";

import { DefinedView } from "../clusterModal/ClusterModal";

export interface FaultEventsWidgetProps {
  setAffectedPvins: Dispatch<SetStateAction<AffectedVehicleAttributes[] | undefined>>;
  cluster: Cluster;
  setModalView: Dispatch<SetStateAction<DefinedView | undefined>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export function FaultEventsWidget(props: FaultEventsWidgetProps) {
  return (
    <BasicWidget title={"Fault Events"}>
      <FaultEventsTable
        cluster={props.cluster}
        setAffectedPvins={props.setAffectedPvins}
        setModalView={props.setModalView}
        setLoading={props.setLoading}
      />
    </BasicWidget>
  );
}
