import { useNavigate } from "@tanstack/react-router";
import EChartsReact from "echarts-for-react";
import _ from "lodash";
import { RefObject, useContext, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FeatureId } from "@/api";
import {
  Fault,
  RepairEventHistory,
  RepairEventType,
  TimeSeriesIndependentVar,
  useVehicleHistoricalDataAnalysisQuery,
  useVehicleTimeSeriesQuery,
} from "@/api/customerApi";
import { ValidAppRoutePath } from "@/App";
import { BaseEChart } from "@/components/ui/BaseEChart";
import { QUERY_SETTINGS, UI_SETTINGS } from "@/constants";
import { useChartColoringPool } from "@/contexts/ChartColoringPoolContext";
import { ChartLibraryActions } from "@/contexts/ChartLibrarySlice";
import {
  CustomChartSeries,
  LibraryChart,
  selectFaultFilters,
  selectLibrarySpan,
  selectTimestampEpoch,
} from "@/contexts/ChartLibraryStore";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import SCVContext from "@/contexts/SCVContext";
import {
  buildComparisonTimeSeries,
  buildLibraryDefaultChartConfig,
  buildLibraryFaultChartConfig,
  buildTimeSeriesXAxisValues,
  chartSeriesSorter,
  getFaultYCategory,
  initChartZoomMode,
  repairsSeriesName,
} from "@/features/chartElementLibrary/ChartLibraryBuildUtils";
import ChartLibrarySettings from "@/features/chartElementLibrary/ChartLibrarySettings";
import LibraryChartToolbox from "@/features/chartElementLibrary/LibraryChartToolbox";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { useFeatureFlags } from "@/utils/features";
import { AxisFields, DataZoomFields, EchartsTooltipFormatterParams } from "@/utils/types/EChartsDefinitions";

import { BasicWidget } from "../../components/widget/BasicWidget";
import { getAllFaultElementsInRange, getFaultElementsWithName, getUniqueFaultCodes } from "./ChartFaultUtils";
import { EditorContentWrapper } from "./ChartLibraryEditor.styled";
import { FaultsOnlyChart } from "./FaultsOnlyChart";
import { FaultSeriesValue, RepairSeriesValue } from "./types";

interface ChartLibraryEditorProps {
  chart: LibraryChart;
  independentVar: TimeSeriesIndependentVar;
  onChartDelete?: (chartId: string) => void;
}

const ChartLibraryEditor = ({ chart, independentVar }: ChartLibraryEditorProps) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const isTelemetryEnabled = isFeatureEnabled(FeatureId.Telemetry);

  const { customerIdentifier } = useCustomerIdentifier();
  const { pvin } = useContext(SCVContext);
  const { globalFilter } = useContext(GlobalFilterContext);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const seriesChartRef = useRef<EChartsReact>();
  const faultsChartRef = useRef<EChartsReact>();

  const coloringPoolCtx = useChartColoringPool();

  const librarySpan = useSelector(selectLibrarySpan);
  const { timestampEpoch } = useSelector(selectTimestampEpoch);
  const { faultFilters: selectedFaultFilters } = useSelector(selectFaultFilters);
  const selectedFaultsWithYAxisValue = selectedFaultFilters.map((f) => ({
    ...f,
    value: getFaultYCategory(f),
  }));

  const fields = chart.series.map((s) => {
    return {
      id: s.id,
      aggregateType: s.TimeSeriesFieldInput.aggregateType,
    };
  });
  const comparisonFields: string[] = chart.series.filter((s) => s.comparisonType).map((s) => s.id);
  const withComparison = !!comparisonFields.length;

  const chartRangeInput = librarySpan.rangeInput ?? chart.rangeInput;

  const { data, isLoading, isRefetching } = useVehicleTimeSeriesQuery(
    {
      id: pvin,
      fields,
      independentVarInterval: librarySpan.intervalInput ?? chart.intervalInput,
      independentVar,
      filter: globalFilter,
      withComparison,
      comparisonFields: comparisonFields,
      independentVarRange: chartRangeInput,
      allTimeSeriesFieldIds: fields.map(({ id }) => id),
      timestampEpoch,
    },
    {
      staleTime: QUERY_SETTINGS.LONG_STALE_TIME,
      enabled: !!fields.length && isTelemetryEnabled,
      keepPreviousData: true,
    }
  );

  const { data: historicalEventsData, isLoading: faultsLoading } = useVehicleHistoricalDataAnalysisQuery(
    {
      vehicleId: pvin,
      independentVarInterval: librarySpan.intervalInput ?? chart.intervalInput,
      independentVar,
      independentVarRange: chartRangeInput,
      withXAxis: !fields.length || !isTelemetryEnabled,
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );

  const allFaultEvents = (historicalEventsData?.vehicleFaultHistory?.data ?? []) satisfies Fault[] as Fault[];
  const allRepairEvents = (historicalEventsData?.vehicleRepairEventHistory ||
    []) satisfies RepairEventHistory[] as RepairEventHistory[];

  const faultElements = getAllFaultElementsInRange(
    allFaultEvents,
    selectedFaultFilters,
    independentVar,
    librarySpan.rangeInput
  );

  const faultElementsWithName = getFaultElementsWithName(faultElements, customerIdentifier);

  const handleToggleSettings = () => {
    dispatch(ChartLibraryActions.toggleDrawer());
  };

  const handleSeriesClick = (e: EchartsTooltipFormatterParams) => {
    if (e.seriesName === "faults") {
      const chartFaultValue = e.value as FaultSeriesValue;
      const faultData = chartFaultValue?.[2];
      if (faultData) {
        navigate({
          to: "/fault/$faultCodeId",
          params: { faultCodeId: faultData.code },
          search: {
            eventFilter: {
              ecu: faultData.source,
              troubleCode: faultData.troubleCode ?? undefined,
            },
          },
        });
      }
    }
    if (e.seriesName === repairsSeriesName) {
      const chartRepairValue = e.value as RepairSeriesValue;
      const repairType = chartRepairValue?.[2].type;
      if (repairType) {
        const url: ValidAppRoutePath =
          repairType === RepairEventType.BatteryFullReplacement ? "/repair/full" : "/repair/partial";
        navigate({
          to: url,
        });
      }
    }
  };

  const restoreZoomHandler = () => {
    dispatch(ChartLibraryActions.updateLibrarySpan({}));
  };

  const zoomEventHandler = _.debounce((chart?: EChartsReact) => {
    const chartOptions = chart?.getEchartsInstance().getOption();
    if (chartOptions) {
      const dataZoom = chartOptions.dataZoom as DataZoomFields[];
      const xAxis = chartOptions.xAxis as AxisFields[];
      if (dataZoom.length > 0 && xAxis.length > 0) {
        const zoomStart = Math.floor(dataZoom[0].startValue);
        const zoomEnd = Math.round(dataZoom[0].endValue);

        if (zoomStart && zoomEnd && zoomEnd > zoomStart) {
          dispatch(
            ChartLibraryActions.updateLibrarySpan({
              rangeInput: {
                min: zoomStart,
                max: zoomEnd,
              },
            })
          );
        }
      }
    }
  }, UI_SETTINGS.SEARCH_INPUT_DEBOUNCE);

  let comparisonSeries: CustomChartSeries[] = buildComparisonTimeSeries(
    chart.series.filter((s) => s.comparisonType),
    data?.filteredQuery.vehicleCohortTimeSeries
  );

  function thresholds(t: { cautionThreshold: number; warningThreshold: number } | undefined | null) {
    return t
      ? {
          caution: t.cautionThreshold,
          warning: t.warningThreshold,
        }
      : undefined;
  }

  const xAxisValues = buildTimeSeriesXAxisValues(
    data?.vehicleTimeSeries.x ?? historicalEventsData?.vehicleTimeSeriesXAxis?.x ?? [],
    data?.filteredQuery?.vehicleCohortTimeSeries?.x ?? [],
    chartRangeInput
  );

  const populatedChart: LibraryChart = {
    ...chart,
    axisData: {
      ...chart.axisData,
      data: xAxisValues,
      id: independentVar,
    },
    series: [
      ...chart.series.map((s, i) => {
        return {
          ...s,
          data: data?.vehicleTimeSeries.y[i] || [],
          xValues: data?.vehicleTimeSeries.x ?? [],
          anomalyThreshold: thresholds(data?.anomalyDetectorsForTimeSeriesFieldIds[i]),
        };
      }),
      ...comparisonSeries,
    ] // Set comparison series next to main ones
      .sort(chartSeriesSorter),
  };

  const intervalData = data?.vehicleTimeSeries.independentVarInterval;

  const chartHasNoSeries = populatedChart.series.length <= 0;

  // If we use the independentVar prop then the chart units change so the values show up strange so we use the
  // independent var from the api result so the units will match the data AMAYA-3908.
  const indVarForChart = data?.vehicleTimeSeries.independentVarInterval
    ? data?.vehicleTimeSeries.independentVarInterval.timeUnit
      ? TimeSeriesIndependentVar.Time
      : TimeSeriesIndependentVar.Mileage
    : independentVar;

  const chartOptions = buildLibraryDefaultChartConfig({
    chart: populatedChart,
    faults: !!selectedFaultFilters.length,
    repairs: allRepairEvents ?? [],
    indVar: indVarForChart,
    indInterval: intervalData,
    acquireColor: coloringPoolCtx === null ? (_: string) => "#000" : coloringPoolCtx.acquireColor,
    showXAxis: chartHasNoSeries,
  });

  const uniqueFaultCodes = useMemo(() => getUniqueFaultCodes(faultElementsWithName), [faultElementsWithName]);

  return (
    <BasicWidget
      className="library-active-chart"
      key={chart.chartId}
      noHeaderStyling
      onClick={(e) => e.stopPropagation()}
    >
      <EditorContentWrapper>
        <div className="active-chart-container">
          <LibraryChartToolbox series={populatedChart.series} toggleDrawer={handleToggleSettings} />
          {!isFeatureEnabled(FeatureId.Telemetry) && (
            <FaultsOnlyChart
              independentVar={independentVar}
              faultElementsWithName={faultElementsWithName}
              faultsLoading={faultsLoading}
              faultsChartRef={faultsChartRef}
              handleSeriesClick={handleSeriesClick}
              zoomEventHandler={zoomEventHandler}
              restoreZoomHandler={restoreZoomHandler}
              initChartZoomMode={initChartZoomMode}
              xAxis={historicalEventsData?.vehicleTimeSeriesXAxis?.x ?? []}
              uniqueFaultCodes={uniqueFaultCodes}
              allRepairEvents={allRepairEvents}
              selectedFaultsWithYAxisValue={selectedFaultsWithYAxisValue}
            />
          )}
          {isFeatureEnabled(FeatureId.Telemetry) && (
            <>
              <div className="libary-chart-scrollable-container">
                {selectedFaultFilters.length ? (
                  <BaseEChart
                    style={{ height: selectedFaultsWithYAxisValue.length * 20 || 60 }}
                    className="library-fault-chart"
                    {...buildLibraryFaultChartConfig(
                      populatedChart,
                      faultElementsWithName,
                      selectedFaultsWithYAxisValue,
                      independentVar
                    )}
                    showLoading={faultsLoading && !isLoading && !isRefetching}
                    ref={faultsChartRef as RefObject<EChartsReact>}
                    onChartReady={(chart) => initChartZoomMode(faultsChartRef.current?.getEchartsInstance() || chart)}
                    onEvents={{
                      click: (e: EchartsTooltipFormatterParams) => {
                        handleSeriesClick(e);
                      },
                      datazoom: () => {
                        zoomEventHandler(faultsChartRef.current);
                      },
                      restore: () => {
                        zoomEventHandler(faultsChartRef.current);
                        initChartZoomMode(faultsChartRef.current?.getEchartsInstance());
                        restoreZoomHandler();
                      },
                    }}
                    showEmptyChart={true}
                    notMerge={true}
                  />
                ) : undefined}
              </div>
              <div className={uniqueFaultCodes > 10 ? "library-chart-wrapper-with-padding" : ""}>
                <BaseEChart
                  className="library-chart"
                  style={{ height: chartHasNoSeries ? 150 : Math.max(900 - uniqueFaultCodes * 20, 700) || 1 }}
                  {...chartOptions}
                  showLoading={(isLoading || isRefetching) && !!fields.length}
                  showLoadingMask={isRefetching ? false : true}
                  ref={seriesChartRef as RefObject<EChartsReact>}
                  onChartReady={(chart) => initChartZoomMode(seriesChartRef.current?.getEchartsInstance() || chart)}
                  onEvents={{
                    click: (e: EchartsTooltipFormatterParams) => {
                      handleSeriesClick(e);
                    },
                    datazoom: () => {
                      zoomEventHandler(seriesChartRef.current);
                    },
                    restore: () => {
                      zoomEventHandler(seriesChartRef.current);
                      initChartZoomMode(seriesChartRef.current?.getEchartsInstance());
                      restoreZoomHandler();
                    },
                  }}
                  notMerge={true}
                />
              </div>
            </>
          )}
        </div>
        <ChartLibrarySettings />
      </EditorContentWrapper>
    </BasicWidget>
  );
};

export default ChartLibraryEditor;
