import { SingleVehicleAnomalyDetector } from "@/api/customerApi";
import { getLast90DaysFromSpecificDate } from "@/utils/dateUtils";

export const getLast90DaysIndexFromMostRecentDate = (anomaly: SingleVehicleAnomalyDetector) => {
  const xValues = anomaly.vehicleData.history.x;
  const mostRecentDate = xValues[xValues.length - 1];
  const ninetyDaysAgoFromMostRecentDate = getLast90DaysFromSpecificDate(mostRecentDate);
  return Math.max(
    // @ts-ignore
    xValues.findLastIndex((date: number) => date < ninetyDaysAgoFromMostRecentDate),
    0
  );
};
