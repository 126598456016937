import { useParams } from "@tanstack/react-router";
import { Tooltip } from "antd";

import { useVehicleDetailsQuery, VehicleCohortEpoch } from "@/api/customerApi";
import { QUERY_SETTINGS } from "@/constants";

// Syncs with vehicleDetails data and exposes relevant information for comparison with xAxis is timestamp
const useVehicleComparisonEpochData = () => {
  const { pvin } = useParams({ strict: false });

  const { data } = useVehicleDetailsQuery(
    { id: pvin ?? "" },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME, enabled: !!pvin }
  );

  const vehicleDetails = data?.vehicleDetails[0];

  const vehicleHasValidComparisonEpoch = !!vehicleDetails?.deliveryDate || !!vehicleDetails?.productionDate;

  const defaultComparisonEpoch =
    (vehicleDetails?.deliveryDate ? VehicleCohortEpoch.DaysSinceDelivery : undefined) ??
    (vehicleDetails?.productionDate ? VehicleCohortEpoch.DaysSinceProduction : undefined);

  const availableComparisonEpochs = [
    {
      key: VehicleCohortEpoch.DaysSinceDelivery,
      label: vehicleDetails?.deliveryDate ? (
        "Days since delivery"
      ) : (
        <Tooltip title="Vehicle Delivery Date not found" placement="left">
          Days since delivery
        </Tooltip>
      ),
      disabled: !vehicleDetails?.deliveryDate,
    },
    {
      key: VehicleCohortEpoch.DaysSinceProduction,
      label: vehicleDetails?.productionDate ? (
        "Days since production"
      ) : (
        <Tooltip title="Vehicle Production Date not found" placement="left">
          Days since production
        </Tooltip>
      ),
      disabled: !vehicleDetails?.productionDate,
    },
  ];

  return {
    vehicleDetails,
    vehicleHasValidComparisonEpoch,
    availableComparisonEpochs,
    defaultComparisonEpoch,
  };
};

export default useVehicleComparisonEpochData;
