import VehicleFilterIcon from "@amayaIcons/VehicleFilter.svg";
import { Badge, Button, Tooltip } from "antd";
import _ from "lodash";
import { Dispatch, SetStateAction, useContext } from "react";

import { useAllFilterValuesQuery } from "@/api/customerApi";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";

import { GlobalFilterContainer } from "./GlobalFilter.styled";

type GlobalFilterProps = {
  setIsActive: Dispatch<SetStateAction<boolean>>;
};

export const GlobalFilter = ({ setIsActive }: GlobalFilterProps) => {
  const { globalFilter } = useContext(GlobalFilterContext);

  const { isLoading } = useAllFilterValuesQuery({}, { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME });

  const countFilters = () => {
    let count = 0;
    Object.values(globalFilter["vehicle"]).forEach((filter) => {
      count += filter.length;
    });
    return count;
  };

  // will have to be changed if we add more filter values
  const globalFilterApplied = Object.values(globalFilter["vehicle"]).some((arr) => !_.isEmpty(arr));
  const filtercount = countFilters();

  const containerClassName = `filter-cta body-medium${globalFilterApplied ? " active" : ""}`;

  return (
    <GlobalFilterContainer>
      <Tooltip placement="left" title="Vehicle Filters">
        <Button
          type={globalFilterApplied ? "text" : "default"}
          className={containerClassName}
          onClick={() => setIsActive((prev) => !prev)}
          loading={isLoading}
          disabled={isLoading}
        >
          <Badge count={filtercount} overflowCount={10}>
            <VehicleFilterIcon />
          </Badge>
        </Button>
      </Tooltip>
    </GlobalFilterContainer>
  );
};
