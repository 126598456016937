import { InfoCircleOutlined } from "@ant-design/icons";
import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { DEFAULT_CURRENT_PAGE } from "@lib/src/table/defaults";
import { Link, useSearch } from "@tanstack/react-router";
import { ColumnType } from "antd/lib/table";
import { useContext } from "react";

import { FeatureId, Pagination } from "@/api";
import { EventFilter, EventOccurrence, useEventOccurrencesQuery } from "@/api/customerApi";
import FriendlyCountryLabel from "@/components/ui/FriendlyCountryLabel";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { INFERRED_DATE_JSX_MSG } from "@/features/vehicleDetails/FaultHistoryByVehicle";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { parseDateToMdStr } from "@/utils/dateUtils";
import { useFeatureFlags } from "@/utils/features";
import { formatNumber } from "@/utils/numberUtils";
import { toModelDescription } from "@/utils/vehicleModel";

import StatefulTable from "../tables/StatefulTable";
import StyledTooltip from "../ui/StyledTooltip";

type EventOccurrencesTableProps = {
  eventFilter: EventFilter;
};

export default function EventOccurrencesTable({ eventFilter }: EventOccurrencesTableProps) {
  const { globalFilter } = useContext(GlobalFilterContext);
  const { isFeatureEnabled } = useFeatureFlags();

  const showVIN = isFeatureEnabled(FeatureId.Vins);

  const tableState = useSearch({ strict: false, select: (search) => search.eventOccurrencesTable });

  const currentPagination: Pagination = {
    currentPage: tableState?.pagination?.currentPage || DEFAULT_CURRENT_PAGE,
    pageSize: tableState?.pagination?.pageSize || DEFAULT_PAGE_SIZE,
  };

  const currentSorting = tableState?.sorting;

  const { data, isFetching } = useEventOccurrencesQuery(
    {
      eventFilter,
      filter: globalFilter,
      pagination: currentPagination,
      sorters: currentSorting,
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );

  const { customerIdentifier } = useCustomerIdentifier();

  const total = data?.filteredQuery.eventOccurrences.pagination.totalCount;

  const columns: ColumnType<EventOccurrence>[] = [
    {
      key: "date",
      dataIndex: "date",
      title: "Last Occurrence",
      sorter: true,
      defaultSortOrder: "descend",
      width: 156,
      tooltipMessage: (date, eventOccurrence) => {
        const { isDateInferred } = eventOccurrence;
        const dateString = date ? parseDateToMdStr(new Date(date)) : "-";
        return isDateInferred ? dateString : "";
      },
      render: (date, eventOccurrence) => {
        const { isDateInferred } = eventOccurrence;
        const dateString = date ? parseDateToMdStr(new Date(date)) : "-";
        if (isDateInferred) {
          return (
            <StyledTooltip title={INFERRED_DATE_JSX_MSG} showArrow={false}>
              <span className="event-occurence-date error">
                <InfoCircleOutlined /> {dateString}
              </span>
            </StyledTooltip>
          );
        }
        return <span className="event-occurence-date">{dateString}</span>;
      },
    },
    {
      key: "eventCount",
      dataIndex: "eventCount",
      title: "Occurrences",
      sorter: true,
      width: 128,
    },
    {
      key: "vehicleModel",
      title: "Model",
      sorter: true,
      render: (_, occurrence) => toModelDescription(occurrence.vehicle, customerIdentifier.models) ?? "-",
    },
    {
      key: "vehicleYear",
      title: "Year",
      sorter: true,
      render: (_, occurrence) => occurrence.vehicle?.year ?? "-",
    },
    {
      key: "country",
      title: "Country",
      sorter: true,
      render: (_, occurrence) =>
        occurrence.vehicle?.country ? <FriendlyCountryLabel countryCode={occurrence.vehicle?.country} /> : "-",
      width: 128,
    },
    {
      key: "odometer",
      dataIndex: "odometer",
      title: "Odometer (km)",
      sorter: true,
      render: (_, occurrence) => (occurrence.odometer ? formatNumber(occurrence.odometer) : "-"),
      width: 128,
    },
    {
      key: "vehicle",
      title: showVIN ? "VIN" : "PVIN",
      dataIndex: showVIN ? "vehicle.vin" : "vehicle.id",
      tooltipMessage: (_, occurrence) => occurrence.vehicle?.[showVIN ? "vin" : "id"],
      render: (_, occurrence) =>
        occurrence.vehicle?.id ? (
          <Link to="/vehicle/$pvin" params={{ pvin: occurrence.vehicle.id }}>
            {occurrence.vehicle[showVIN ? "vin" : "id"]}
          </Link>
        ) : null,
    },
  ];

  return (
    <StatefulTable
      searchKey="eventOccurrencesTable"
      loading={isFetching}
      dataSource={data?.filteredQuery.eventOccurrences.occurrences.map((d, i) => ({ key: d.vehicle?.id ?? i, ...d }))}
      columns={columns}
      rowKey={(row) => row.id}
      pagination={{
        total,
        pageSize: currentPagination.pageSize,
        currentPage: currentPagination.currentPage,
      }}
      sorting={currentSorting}
    />
  );
}
