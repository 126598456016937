import { useState } from "react";

import { TimeSeriesFieldFamily } from "@/api";
import { AggregateType, ErrorType } from "@/api/customerApi";
import { StyledSelect } from "@/components/form/input/BasicSelect.styled";
import { AnalysisSignal } from "@/contexts/EventAnalysisChartStore";
import {
  getAggregateOptionValue,
  getAvailableEventAggregationsOptions,
} from "@/features/chartElementLibrary/FieldPlotterHelper";
import { FieldPlotterContainer } from "@/features/chartElementLibrary/LibraryFieldPlotter.styled";

const availableAggregations = getAvailableEventAggregationsOptions();
const errorTypeDictionary = {
  Quartile: "Top & Bottom Quartiles",
  MinMax: "Min & Max",
  StdDev: "+/- Std. Deviation",
  Vigintile: "Top & Bottom 5 percent",
};
const errorType = Object.keys(ErrorType).map((e) => ({ value: e, label: errorTypeDictionary[e as "Quartile"] }));

interface LibraryFieldPlotterProps {
  name: string;
  id: string;
  fieldId: string;
  plottedData?: AnalysisSignal;
  fieldDisplayName?: string;
  signalType: TimeSeriesFieldFamily;
  unit: string;
  eventPlotterOptionsDraft: Map<string, AnalysisSignal>;
  onPlotOptionsChange: (signalsData: AnalysisSignal) => void;
}

export const EventFieldPlotter = ({
  name,
  id,
  fieldId,
  plottedData,
  signalType,
  unit,
  eventPlotterOptionsDraft,
  onPlotOptionsChange,
}: LibraryFieldPlotterProps) => {
  // Simple storage for options draft
  const optionsDraft = eventPlotterOptionsDraft.get(fieldId);
  // state to re-render component after optionsDraft is updated
  const [, setDraftLastUpdated] = useState(new Date().getTime());

  const selectedAggregation =
    plottedData?.fieldInput.aggregateType || optionsDraft?.fieldInput?.aggregateType || AggregateType.Median;
  const selectedComparisons =
    plottedData?.fieldInput.errorType || optionsDraft?.fieldInput.errorType || ErrorType.Quartile;

  const onAggregationSelect = (aggOption?: AggregateType) => {
    if (!plottedData && aggOption) {
      eventPlotterOptionsDraft.set(fieldId, {
        id: fieldId,
        name: name,
        fieldInput: {
          id: fieldId,
          aggregateType: aggOption,
          family: signalType,
          errorType: optionsDraft?.fieldInput.errorType || ErrorType.Quartile,
        },
      });
      return setDraftLastUpdated(new Date().getTime());
    }

    if (aggOption) {
      onPlotOptionsChange({
        name,
        id,
        fieldInput: {
          id: fieldId,
          aggregateType: aggOption,
          errorType: selectedComparisons,
          family: signalType,
        },
        unit,
      });
    }
  };

  const onAreaComparisonSelect = (areaComparisonOption?: ErrorType) => {
    if (!plottedData && areaComparisonOption) {
      eventPlotterOptionsDraft.set(fieldId, {
        id: fieldId,
        name: name,
        fieldInput: {
          id: fieldId,
          aggregateType: optionsDraft?.fieldInput?.aggregateType || AggregateType.Median,
          family: signalType,
          errorType: areaComparisonOption,
        },
      });
      return setDraftLastUpdated(new Date().getTime());
    }
    if (areaComparisonOption) {
      onPlotOptionsChange({
        name,
        id,
        fieldInput: {
          id: fieldId,
          aggregateType: selectedAggregation,
          errorType: areaComparisonOption,
          family: signalType,
        },
        unit,
      });
    }
  };

  return (
    <FieldPlotterContainer>
      <div className="field-control">
        <p className="body-small">Aggregation</p>
        <StyledSelect
          placeholder="Select Aggregation"
          options={availableAggregations}
          value={selectedAggregation}
          onSelect={(value) => onAggregationSelect(getAggregateOptionValue(value as string))}
        />
      </div>
      <div className="field-control">
        <div />
        <StyledSelect
          placeholder="Select Error Type"
          options={errorType}
          value={selectedComparisons}
          onSelect={(value) => onAreaComparisonSelect(ErrorType[value as "MinMax"])}
        />
      </div>
      {/* {signalType === signalType.Expression && <div className="field-control"></div>} */}
    </FieldPlotterContainer>
  );
};
