import { DeleteOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGINATION } from "@lib/src/table/defaults";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import { Modal, notification } from "antd";
import { ColumnType } from "antd/es/table";

import {
  CalculatedStatus,
  Pagination,
  useDeleteExpressionEventMutation,
  UserCreatedEvent,
  useRecentEventsQuery,
  useUserEventsQuery,
} from "@/api";
import { PatternLink } from "@/components/pattern/PatternLink";
import StatefulTable from "@/components/tables/StatefulTable";
import TableActions from "@/components/tables/TableActions";
import { TableActionItem } from "@/components/tables/TableActions.styled";
import FromNowFormatter from "@/components/ui/FromNowFormatter";
import { Route } from "@/routes/event-analysis";
import { getCurrentCustomerId, requireCurrentCustomerId } from "@/utils/customers";
import { humanFriendlyStatuses } from "@/utils/patterns";

const { confirm } = Modal;

type EventRow = Omit<UserCreatedEvent, "expression"> & {
  expression: Pick<UserCreatedEvent["expression"], "id" | "name">;
};

export const UserEventsTable = () => {
  const queryClient = useQueryClient();

  const eventsNavigate = Route.useNavigate();

  const tableState = Route.useSearch({ select: (search) => search.patternEventsTable });

  const currentPagination: Pagination = {
    currentPage: tableState?.pagination?.currentPage || DEFAULT_CURRENT_PAGE,
    pageSize: tableState?.pagination?.pageSize || DEFAULT_PAGE_SIZE,
  };

  const currentSorting = tableState?.sorting;

  const { data, isLoading, isRefetching } = useUserEventsQuery({
    customerId: getCurrentCustomerId() ?? "",
    pagination: currentPagination,
    sorting: currentSorting,
  });

  const tableData = data?.userEvents?.expressionEvents;
  const totalDataCount = data?.userEvents?.pagination?.totalCount ?? 0;

  const deleteExpressionEventMutation = useDeleteExpressionEventMutation({
    onSettled: () => {
      eventsNavigate({
        search: (prev) => ({
          ...prev,
          patternEventsTable: {
            ...prev.patternEventsTable,
            pagination: { ...DEFAULT_PAGINATION },
          },
        }),
      });
    },
  });
  const [notificationApi, notificationContextHolder] = notification.useNotification();

  const isDeleting = deleteExpressionEventMutation.isLoading;

  function onClickDelete({ id, version }: EventRow) {
    confirm({
      title: "Confirm Delete",
      icon: <ExclamationCircleFilled />,
      content: "Deleting this user event cannot be undone. Confirm?",
      onOk() {
        const options = {
          async onSuccess() {
            notificationApi.success({ message: `Event deleted.` });
            await queryClient.invalidateQueries({
              queryKey: useRecentEventsQuery.getKey({}),
            });
            await queryClient.invalidateQueries({
              queryKey: useUserEventsQuery.getKey({ customerId: getCurrentCustomerId() ?? "" }),
            });
          },
        };
        deleteExpressionEventMutation.mutate(
          {
            customerId: requireCurrentCustomerId(),
            id,
            version,
          },
          options
        );
      },
    });
  }

  const columns: ColumnType<EventRow>[] = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      sorter: true,
      render: (_: string, row: EventRow) => {
        return (
          <Link to="/pattern-event/$patternEventId" params={{ patternEventId: row.id }}>
            {row.name}
          </Link>
        );
      },
    },
    {
      key: "lastModified",
      title: "Last Modified",
      dataIndex: "lastModified",
      width: 180,
      sorter: true,
      render: (_: string, row: EventRow) => {
        return <FromNowFormatter value={new Date(row.lastModified)} />;
      },
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      width: 120,
      sorter: true,
      render: (status: string) => humanFriendlyStatuses(status as CalculatedStatus),
    },
    {
      key: "pattern",
      title: "Pattern",
      dataIndex: "expression",
      render: (_: string, row: EventRow) => {
        return <PatternLink name={row.expression.name} id={row.expression.id} />;
      },
    },
    {
      key: "actions",
      dataIndex: "",
      width: 75,
      render: (_: string, row: EventRow) => {
        const menuItems = [
          {
            key: "delete",
            label: (
              <TableActionItem onClick={() => onClickDelete(row)}>
                <DeleteOutlined /> Delete
              </TableActionItem>
            ),
          },
        ];
        return <TableActions menu={menuItems} />;
      },
    },
  ];

  return (
    <>
      <StatefulTable
        searchKey="patternEventsTable"
        dataSource={tableData}
        pagination={{
          total: totalDataCount,
          pageSize: currentPagination.pageSize,
          currentPage: currentPagination.currentPage,
        }}
        sorting={currentSorting}
        columns={columns}
        rowKey={(row) => row.id}
        loading={isLoading || isRefetching || isDeleting}
      />
      {notificationContextHolder}
    </>
  );
};
