import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { isNotUndefined } from "@lib/src/isNotUndefined";
import { Switch } from "antd";
import React, { PropsWithChildren, useState } from "react";

import { useChartColoringPool } from "@/contexts/ChartColoringPoolContext";

import { getPlotColorByIndex } from "./ChartLibraryBuildUtils";
import { LibraryItemBodyContainer, LibraryItemContainer, LibraryItemHeaderContainer } from "./LibraryItem.styled";

interface PlottedSeriesIconProps {
  index: number;
  color?: string;
}

const PlottedSeriesIcon = ({ index, color }: PlottedSeriesIconProps) => {
  const bgColor = color || (index >= 0 ? getPlotColorByIndex(index) : "transparent");
  return <div className="plotted-series-icon" style={{ backgroundColor: bgColor }}></div>;
};

type LibraryItemProps = PropsWithChildren<{
  label: React.ReactNode;
  subLabel?: string;
  plottedIndex?: number;
  isSelected?: boolean;
  onToggle?: (selected: boolean) => void;
  fieldId?: string;
}>;

export const LibraryItem = ({
  label,
  subLabel,
  children,
  plottedIndex,
  isSelected,
  onToggle,
  fieldId,
}: LibraryItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const isHighlighted = isNotUndefined(plottedIndex) && plottedIndex >= 0;

  const handleSignalItemExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const coloringPoolCtx = useChartColoringPool();

  return (
    <LibraryItemContainer
      className={`library-signal-item${isExpanded ? " expanded" : ""}${isHighlighted ? " highlight" : ""}`}
    >
      <LibraryItemHeaderContainer onClick={handleSignalItemExpand}>
        <div className="header-section">
          {onToggle && (
            <Switch
              className="library-item-switch"
              checked={isSelected}
              onChange={(checked, e) => {
                e.stopPropagation();
                onToggle(checked);
              }}
            />
          )}
          <span className="body-medium dark no-select">{label}</span>
        </div>
        <div className="header-section">
          {subLabel ? <span className="body-medium light">{subLabel}</span> : undefined}
          {isHighlighted && (
            <PlottedSeriesIcon index={plottedIndex} color={coloringPoolCtx?.acquireColor(fieldId ?? "none")} />
          )}
          {!isExpanded && <RightOutlined className="signal-plotter-header-icon" />}
          {isExpanded && <DownOutlined className="signal-plotter-header-icon" />}
        </div>
      </LibraryItemHeaderContainer>
      {isExpanded && <LibraryItemBodyContainer>{children}</LibraryItemBodyContainer>}
    </LibraryItemContainer>
  );
};
