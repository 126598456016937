import { DEFAULT_PAGINATION } from "@lib/src/table/defaults";
import { createFileRoute } from "@tanstack/react-router";

import { ClusterSortField, SortOrder } from "@/api";
import { TableState } from "@/components/tables/StatefulTable.types";
import { ClusterHome } from "@/features/cluster/clusterHome/ClusterHome";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

const defaultTableState: TableState = {
  pagination: DEFAULT_PAGINATION,
  sorting: { field: ClusterSortField.Created, order: SortOrder.Descend },
};

export type ClusterHomeSearch = {
  clusterTable?: TableState;
};

export const Route = createFileRoute("/cluster/")({
  component: () => (
    <AppMainLayout>
      <ClusterHome />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad("Clusters"),
  validateSearch: (search: Record<string, unknown>): ClusterHomeSearch => {
    return {
      clusterTable: (search.clusterTable as TableState) || defaultTableState,
    };
  },
});
