import styled from "styled-components";

export const FilterHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

export const FilterSelector = styled.div`
  position: absolute;
  max-height: 600px;
  min-width: 290px;
  background: white;
  z-index: 100;
  left: -15%;
  top: 10%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px 3px;
`;

export const FilterOptionWrapper = styled.div`
  cursor: pointer;
  height: 30px;
  padding: 0px 10px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  &:hover {
    background: ${({ theme }) => theme.colors.hoverRow};
  }
`;

export const AddFilterWrapper = styled.div`
  cursor: pointer;
  height: 2rem;
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.colors.lightGray};
  gap: 0.25rem;
  .add-filter-cta-icon {
    font-size: 0.75rem;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.defaultGray};
  }
`;

export const SelectedFiltersWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  gap: 0.5rem;
`;

export const SelectedFilterWrapper = styled.div`
  height: 2rem;
  position: relative;
  display: flex;
  align-items: center;
  min-width: max-content;
`;
