import { DEFAULT_PAGINATION } from "@lib/src/table/defaults";
import { createFileRoute } from "@tanstack/react-router";

import { EventOccurrencesSortField, EventType, RelatedEventsSortField, SortOrder } from "@/api";
import { TableState } from "@/components/tables/StatefulTable.types";
import EventAnalyzer from "@/features/event/EventAnalyzer";
import { FaultEventAnalysisFilter, FaultEventAnalysisSearchFilter } from "@/hooks/useEventAnalysisSearch";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";
import { EventAnalysisSearch } from "./event-analysis";

export const defaultEventOccurrencesTableState: TableState = {
  pagination: DEFAULT_PAGINATION,
  sorting: { field: EventOccurrencesSortField.Date, order: SortOrder.Descend },
};

export const defaultRelatedEventsTableState: TableState = {
  pagination: DEFAULT_PAGINATION,
  sorting: { field: RelatedEventsSortField.UniqueVehicles, order: SortOrder.Descend },
};

export const Route = createFileRoute("/fault/$faultCodeId")({
  component: () => (
    <AppMainLayout>
      <EventAnalyzer eventType={EventType.Fault} />
    </AppMainLayout>
  ),
  beforeLoad: ({ params }) => setTitleBeforeLoad(params.faultCodeId),
  validateSearch: (search: Record<string, unknown>): FaultEventAnalysisSearchFilter | EventAnalysisSearch => ({
    xEventAnalysisChart: (search.xEventAnalysisChart as string) || undefined,
    eventFilter: (search.eventFilter as FaultEventAnalysisFilter) || undefined,
    eventOccurrencesTable: (search.eventOccurrencesTable as TableState) || defaultEventOccurrencesTableState,
    relatedEventsTable: (search.relatedEventsTable as TableState) || defaultRelatedEventsTableState,
  }),
});
