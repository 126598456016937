import { createFileRoute } from "@tanstack/react-router";

import { EventType, RepairEventType } from "@/api";
import { TableState } from "@/components/tables/StatefulTable.types";
import { getRepairEventLabel } from "@/features/chartElementLibrary/ChartLibraryBuildUtilsTooltips";
import EventAnalyzer from "@/features/event/EventAnalyzer";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";
import { EventAnalysisSearch } from "./event-analysis";
import { defaultEventOccurrencesTableState, defaultRelatedEventsTableState } from "./fault.$faultCodeId";

export const Route = createFileRoute("/repair/full")({
  component: () => (
    <AppMainLayout>
      <EventAnalyzer eventType={EventType.Repair} repairType={RepairEventType.BatteryFullReplacement} />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad(getRepairEventLabel(RepairEventType.BatteryFullReplacement)),
  validateSearch: (search: Record<string, unknown>): EventAnalysisSearch => ({
    xEventAnalysisChart: (search.xEventAnalysisChart as string) || undefined,
    eventOccurrencesTable: (search.eventOccurrencesTable as TableState) || defaultEventOccurrencesTableState,
    relatedEventsTable: (search.relatedEventsTable as TableState) || defaultRelatedEventsTableState,
  }),
});
