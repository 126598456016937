import ExclIcon from "@amayaIcons/Exclude.svg";
import InclIcon from "@amayaIcons/Include.svg";
import { AutoComplete, Radio } from "antd";
import { RadioChangeEvent } from "antd/lib";
import React, { Dispatch, SetStateAction } from "react";

import { GroupFilterCriteria } from "@/api";
import Loading from "@/components/loading";

import { FilterSelection } from "./FilterHeader";
import { CriteriaSelectorsContainer, FilterCriteriaOptionContainer, VisualWrapper } from "./TableFilter.styled";

type TableGroupFilterProps = {
  label: React.ReactNode;
  localSelections: FilterSelection;
  setLocalSelections: Dispatch<SetStateAction<FilterSelection>>;
  search: string;
  selectOpen: boolean;
  isLoading: boolean;
  handleSearchChange: (value: string) => void;
  handleSelect: (selection: FilterSelection["values"][number]) => void;
  getOptions: () => {
    value: string | undefined;
    label: string;
  }[];
  setSelectOpen: Dispatch<SetStateAction<boolean>>;
};
export const TableGroupFilter = ({
  localSelections,
  setLocalSelections,
  label,
  search,
  selectOpen,
  isLoading,
  handleSearchChange,
  handleSelect,
  getOptions,
  setSelectOpen,
}: TableGroupFilterProps) => {
  const handleGroupCriteriaChange = (e: RadioChangeEvent) => {
    const selectedCriteria = (e.target.value as GroupFilterCriteria) ?? GroupFilterCriteria.Incl;
    setLocalSelections({ ...localSelections, criteria: selectedCriteria });
  };

  const handleNullCriteriaChange = (e: RadioChangeEvent) => {
    const includeNulls = !!e.target.value;
    setLocalSelections({ ...localSelections, includeNulls });
  };

  return (
    <VisualWrapper>
      <h3 className="heading-xx-small">{label}</h3>
      <CriteriaSelectorsContainer className="filter-criteria-wrapper">
        {/* Group Filter Criteria */}
        <Radio.Group onChange={handleGroupCriteriaChange} value={localSelections.criteria} size="large">
          <Radio.Button value={GroupFilterCriteria.Incl}>
            <FilterCriteriaOptionContainer className="criteria-option-container">
              <InclIcon /> Include Group
            </FilterCriteriaOptionContainer>
          </Radio.Button>
          <Radio.Button value={GroupFilterCriteria.Excl}>
            <FilterCriteriaOptionContainer className="criteria-option-container">
              <ExclIcon /> Exclude Group
            </FilterCriteriaOptionContainer>
          </Radio.Button>
        </Radio.Group>
        {/* Nulls Filter Criteria */}
        <Radio.Group onChange={handleNullCriteriaChange} value={localSelections.includeNulls} size="large">
          <Radio.Button value={true}>
            <FilterCriteriaOptionContainer className="criteria-option-container">
              <InclIcon /> Include Nulls
            </FilterCriteriaOptionContainer>
          </Radio.Button>
          <Radio.Button value={false}>
            <FilterCriteriaOptionContainer className="criteria-option-container">
              <ExclIcon /> Exclude Nulls
            </FilterCriteriaOptionContainer>
          </Radio.Button>
        </Radio.Group>
      </CriteriaSelectorsContainer>
      <AutoComplete
        value={search}
        open={selectOpen}
        onFocus={() => setSelectOpen(true)}
        onBlur={() => setSelectOpen(false)}
        placeholder={`Type to Select ${label}s`}
        options={getOptions()}
        onChange={(value) => handleSearchChange(value)}
        onSelect={(_, selection) => handleSelect(selection)}
        notFoundContent={
          search === "" ? null : isLoading ? <Loading size="small" label="Searching..." /> : <div>No results found</div>
        }
      />
    </VisualWrapper>
  );
};
