import { useNavigate } from "@tanstack/react-router";
import { Button, Form, Input } from "antd";
import { NotificationInstance } from "antd/es/notification/interface";
import { useState } from "react";

import { ErrorKey, useCopyClusterMutation } from "@/api";
import { GraphQLErrorException } from "@/hooks/useApiFetcher";
import { defaultDiagnosticTableState, defaultFaultTableState } from "@/routes/cluster.$clusterId";
import { getCurrentCustomerId } from "@/utils/customers";
import ApiError from "@/utils/errors/ApiError";

type CopyClusterProps = {
  api: NotificationInstance;
  clusterId: string;
  clusterName: string | undefined;
  clusterDescription: string | undefined | null;
  onSuccess: () => void;
  onCancel: () => void;
};

type FormField = {
  name: string;
  description: string;
};

export const CopyCluster = ({
  api,
  clusterId,
  onSuccess,
  onCancel,
  clusterName,
  clusterDescription,
}: CopyClusterProps) => {
  const [form] = Form.useForm();
  const [isCopyingCluster, setIsCopyingCluster] = useState(false);

  const copyCluster = useCopyClusterMutation({
    retry: (failureCount, error: ApiError) => {
      const exception = error.cause?.extensions?.exception as GraphQLErrorException | undefined;
      const errorCode: string = exception?.errorCode ?? ErrorKey.GenericError;
      if (errorCode === ErrorKey.UniquenessConstraint) {
        return false;
      }
      return failureCount < 3; // Retry up to 3 times for other errors
    },
  });
  const navigate = useNavigate();

  const handleClose = () => {
    form.resetFields();
    onCancel();
  };

  const handleCopy = (args: FormField) => {
    setIsCopyingCluster(true);
    copyCluster.mutate(
      {
        clusterId,
        customerId: getCurrentCustomerId() ?? "",
        name: args.name,
        description: args.description,
      },
      {
        onSuccess: (data) => {
          const clusterId = data.clusters?.copyCluster;
          if (clusterId) {
            api.success({ message: "Cluster copied succesfully" });
            handleClose();
            onSuccess();
            navigate({
              to: "/cluster/$clusterId",
              params: { clusterId: clusterId },
              search: {
                clusterFaultEventsTable: defaultFaultTableState,
                clusterDiagnosticChecksTable: defaultDiagnosticTableState,
              },
            });
          }
        },
        onSettled: () => {
          setIsCopyingCluster(false);
        },
      }
    );
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="basic"
      onFinish={(v: FormField) => {
        handleCopy(v);
      }}
      autoComplete="off"
      initialValues={{
        name: `${clusterName} copy`,
        description: clusterDescription ?? "",
      }}
    >
      <h4 className="heading-x-small">Provide a name for the cluster copy</h4>
      <Form.Item<FormField>
        label={"Name"}
        name={"name"}
        rules={[{ required: true, message: "Please input a name for the cluster copy!" }]}
      >
        <Input placeholder="Enter a name for the new cluster" />
      </Form.Item>
      <Form.Item<FormField> label={"Description"} name="description">
        <Input placeholder="Enter a description for the cluster (optional)" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isCopyingCluster}>
          Create Copy
        </Button>
      </Form.Item>
    </Form>
  );
};
