import styled from "styled-components";

export const FilterFormContainer = styled.div.attrs({ className: "filter-form-container" })`
  padding: 1.5rem;
  background: ${({ theme }) => theme.colors.smoothGray};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  .selectors-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    .select-container {
      min-width: 250px;
    }
    .ant-select {
      width: 180px;
      min-height: 45px;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.darkGray};
      .ant-select-selector {
        svg {
          fill: white;
        }
        border-radius: 0.75rem;
        height: 100%;
        .ant-select-selection-item {
          height: 95%;
          background-color: ${({ theme }) => theme.colors.accentBlue};
          color: white;
          border-radius: 4px;
        }
      }
    }
  }
  .filter-controls {
    display: flex;
    gap: 1.5rem;
  }
`;
