import styled from "styled-components";
export const GlobalHeaderStyled = styled.div`
  min-width: calc(${({ theme }) => theme.breakpoints.md} - ${({ theme }) => theme.sizes.sidebar} - 0.5rem);
  height: ${({ theme }) => theme.sizes.header};
  position: sticky;
  top: 0;
  z-index: 100;
  transition: transform 250ms ease-in-out;
  transform: translateY(0);
  &.filter-active {
    transform: translateY(0);
  }
  &.filter-inactive {
    transform: translateY(-${({ theme }) => theme.sizes.expandedHeader});
  }
  .filter-form-container {
    height: ${({ theme }) => theme.sizes.expandedHeader};
  }
`;

export const ToolbarContainer = styled.div`
  outline: 1px solid ${({ theme }) => theme.colors.lightGray};
  padding: 1rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.defaultWhite};
  box-shadow: 0 4px 10px ${({ theme }) => theme.colors.darkOpacity};
`;
