import { DEFAULT_PAGINATION } from "@lib/src/table/defaults";
import { createFileRoute } from "@tanstack/react-router";

import { FeatureId, SortOrder } from "@/api";
import { AnomalyAffectedVehiclesSortField } from "@/api/customerApi";
import { ProtectedRoute } from "@/components/protectedRoute/ProtectedRoute";
import { TableState } from "@/components/tables/StatefulTable.types";
import AnomalyDetailsPage from "@/features/anomaly/details/AnomalyDetailsPage";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

const defaultTableState: TableState = {
  pagination: DEFAULT_PAGINATION,
  sorting: { field: AnomalyAffectedVehiclesSortField.Score, order: SortOrder.Descend },
};

export type AnomalyDetailSearch = {
  anomalyDetectionsTable?: TableState;
};

export const Route = createFileRoute("/anomaly-detector/$detectorId")({
  component: () => (
    <ProtectedRoute featureId={FeatureId.Anomalies}>
      <AppMainLayout>
        <AnomalyDetailsPage />
      </AppMainLayout>
    </ProtectedRoute>
  ),
  beforeLoad: () => setTitleBeforeLoad("Anomaly Detector"),
  validateSearch: (search: Record<string, unknown>): AnomalyDetailSearch => {
    return {
      anomalyDetectionsTable: (search.anomalyDetectionsTable as TableState) || defaultTableState,
    };
  },
});
