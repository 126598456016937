import { Button, DatePicker } from "antd";
import dayjs from "dayjs";
import { RefObject } from "react";

import { getLast30DaysFromSpecificDate, getLast90DaysFromSpecificDate } from "@/utils/dateUtils";
import { parseDateToStr } from "@/utils/dateUtils";

import { TableFilterDateRangesWrapper } from "./TableFilter.styled";

type TableFilterRangesProps = {
  onSelect: (selection: { label: string; value: { start: number; end: number } }) => void;
  popupRef?: RefObject<HTMLElement>;
};
export const TableFilterDateRanges = ({ onSelect, popupRef }: TableFilterRangesProps) => {
  const { RangePicker } = DatePicker;
  const handleDateSelection = (start: Date | undefined, end: Date | undefined) => {
    if (start && end) {
      const label = `${parseDateToStr(start)} - ${parseDateToStr(end)}`;
      onSelect({
        label,
        value: {
          start: dayjs(start).startOf("day").toDate().getTime(),
          end: dayjs(end).endOf("day").toDate().getTime(),
        },
      });
    }
  };
  return (
    <TableFilterDateRangesWrapper>
      <RangePicker
        size="large"
        onCalendarChange={(d) => {
          const start = d[0]?.toDate();
          const end = d[1]?.toDate();
          handleDateSelection(start, end);
        }}
        value={[undefined, undefined]}
        getPopupContainer={(r) => popupRef?.current ?? r}
      />
      <div className="quick-dates-container">
        <Button
          onClick={() => handleDateSelection(new Date(getLast30DaysFromSpecificDate(new Date().getTime())), new Date())}
          block
        >
          Last 30 Days
        </Button>
        <Button
          onClick={() => handleDateSelection(new Date(getLast90DaysFromSpecificDate(new Date().getTime())), new Date())}
          block
        >
          Last 90 Days
        </Button>
      </div>
    </TableFilterDateRangesWrapper>
  );
};
