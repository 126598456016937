import { useAuth0 } from "@auth0/auth0-react";
import { Button, Select } from "antd";
import _ from "lodash";
import { Dispatch, SetStateAction, useContext, useState } from "react";

import { useAllFilterValuesQuery, VehicleFilterValues } from "@/api/customerApi";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext, { GlobalFilterTypes } from "@/contexts/GlobalFilterContext";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { addToLocalStorage } from "@/utils/localStorageUtils";
import { camelCaseToWords } from "@/utils/randomUtils";
import { consolidateModelStyleOptions, toModelDescription } from "@/utils/vehicleModel";

import { GlobalFilterOption, onFilterSelectChange } from "./GlobalFilter.helpers";
import { FilterFormContainer } from "./GlobalFilterForm.styled";

type vehicleValues = Omit<VehicleFilterValues, "__typename">;
const localStorageKey = "GLOBAL_FILTER";

type GlobalFilterFormProps = {
  setIsActive: Dispatch<SetStateAction<boolean>>;
};

const GlobalFilterForm = ({ setIsActive }: GlobalFilterFormProps) => {
  const { customerIdentifier } = useCustomerIdentifier();

  const { user } = useAuth0();

  const { globalFilter, setGlobalFilter, emptyFilter } = useContext(GlobalFilterContext);
  const [localFilter, setLocalFilter] = useState(_.cloneDeep(globalFilter));

  const { data } = useAllFilterValuesQuery({}, { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME });

  const handleSelectChange = (
    filterEntity: keyof GlobalFilterTypes,
    filterKey: string,
    values: (string | number)[]
  ) => {
    const newFilters = onFilterSelectChange(
      customerIdentifier.models?.model!,
      localFilter,
      filterEntity,
      filterKey,
      values
    );

    setLocalFilter(newFilters);
  };

  const handleApplyFilter = () => {
    const newFilters = {
      ...localFilter,
    };
    setGlobalFilter(newFilters);
    addToLocalStorage(`${localStorageKey}${user?.email ?? ""}`, JSON.stringify(newFilters));
    setIsActive(false);
  };

  function handleClearFilters() {
    const newFilter = _.cloneDeep(emptyFilter);
    setLocalFilter(newFilter);
    setGlobalFilter(newFilter);
    addToLocalStorage(`${localStorageKey}${user?.email ?? ""}`, JSON.stringify(newFilter));
    setIsActive(false);
  }

  const isLocalFilterDifferent = _.isEqual(globalFilter, localFilter);

  const selectItem = (entity: "vehicle", filterType: string, mappedValues: GlobalFilterOption[]) => {
    const isDisabled = filterType === "modelStyle" && localFilter["vehicle"]["model"].length === 0;

    const displayValues =
      filterType === "modelStyle"
        ? consolidateModelStyleOptions(
            localFilter[entity][filterType].map((value) => ({
              label: value,
              value: value,
            })),
            customerIdentifier.models
          ).map((option) => option.value)
        : localFilter[entity as "vehicle"][filterType as keyof vehicleValues];

    return (
      <div className="select-container" key={filterType}>
        <Select
          getPopupContainer={(selectRef) => selectRef}
          mode={"multiple"}
          options={mappedValues.sort((a, b) =>
            typeof a.label === "number" ? a.label - (b.label as number) : a.label.localeCompare(b.label as string)
          )}
          disabled={isDisabled}
          style={{ width: "100%" }}
          placeholder={`Select ${camelCaseToWords(filterType)}`}
          onChange={(changed: (string | number)[] | (string | number)) => {
            let arrayValue = changed;
            if (!_.isArray(arrayValue)) {
              arrayValue = [changed as string | number];
            }
            handleSelectChange(entity, filterType, arrayValue);
          }}
          value={displayValues as (string | number)[]}
        />
      </div>
    );
  };

  const mappedValues = Object.entries((data?.allFilterValues.vehicle as vehicleValues) ?? {});

  return (
    <FilterFormContainer>
      <div className="selectors-container">
        {/* If more types of Filters get added to the global filter we can separate them by type here */}
        {mappedValues.map(([filterType, value]) => {
          if (filterType !== "modelStyle") {
            const mappedValues = value.map((v) => {
              return {
                label:
                  filterType === "model" ? toModelDescription({ model: v as string }, customerIdentifier.models) : v,
                value: v,
              };
            });
            return selectItem(
              "vehicle",
              filterType,
              _.uniqBy(mappedValues, (item) => item.value) as GlobalFilterOption[]
            );
          } else {
            const possibleStyles = localFilter.vehicle.model
              .map((model) => {
                return customerIdentifier.models?.model[model]?.style ?? [];
              })
              .flat();

            const mappedValues = possibleStyles.flatMap((styleRecord) => {
              return Object.keys(styleRecord).map((styleKey) => {
                return { label: styleRecord[styleKey] ?? styleKey, value: styleKey };
              });
            });

            const uniqueValMappedValues = consolidateModelStyleOptions(mappedValues, customerIdentifier.models);

            return selectItem(
              "vehicle",
              filterType,
              _.uniqBy(uniqueValMappedValues, (item) => item.value) as GlobalFilterOption[]
            );
          }
        })}
      </div>
      <div className="filter-controls">
        <Button onClick={handleClearFilters}>Clear Filters</Button>
        <Button type="primary" onClick={handleApplyFilter} disabled={isLocalFilterDifferent}>
          Apply Filters
        </Button>
      </div>
    </FilterFormContainer>
  );
};
export default GlobalFilterForm;
