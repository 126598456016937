import { Select } from "antd";
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";

import {
  AffectedVehicleAttributes,
  AttributeCount,
  AttributeTypeEnum,
  useAttributeCountPvinsQuery,
  useAttributeCountQuery,
} from "@/api/customerApi";
import { BaseEChart } from "@/components/ui/BaseEChart";
import { BasicWidget } from "@/components/widget/BasicWidget";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { DefinedView } from "@/features/cluster/clusterModal/ClusterModal";
import { ClusterViewGraphHeader } from "@/features/cluster/clusterView/ClusterView.styled";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { Route } from "@/routes/cluster.$clusterId";
import { toTitleCase } from "@/utils/toTitleCase";
import { toModelDescription } from "@/utils/vehicleModel";

import { getClusterChartLayoutConfig } from "./clusterChartHelpers";

type ClusterAttributeCountProps = {
  setAffectedPvins: Dispatch<React.SetStateAction<AffectedVehicleAttributes[] | undefined>>;
  setModalView: Dispatch<SetStateAction<DefinedView | undefined>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

const AttributeOptions = [
  { value: AttributeTypeEnum.DeliveryCountry, label: "Delivery Country" },
  { value: AttributeTypeEnum.ModelType, label: "Model" },
  { value: AttributeTypeEnum.ModelYear, label: "Model Year" },
];

export const ClusterAttributeCount = ({ setAffectedPvins, setModalView, setLoading }: ClusterAttributeCountProps) => {
  const { clusterId } = Route.useParams();
  const { globalFilter } = useContext(GlobalFilterContext);
  const [attribute, setAttribute] = useState<AttributeTypeEnum>(AttributeTypeEnum.DeliveryCountry);
  const [attributeValue, setAttributeValue] = useState<string>();
  const { customerIdentifier } = useCustomerIdentifier();

  const attributeMap = useRef(new Map<string, string>());

  const { data, isLoading, isFetching } = useAttributeCountQuery(
    { clusterId, filter: globalFilter, attribute },
    { enabled: !!clusterId }
  );

  const { data: pvins } = useAttributeCountPvinsQuery(
    { clusterId, filter: globalFilter, attribute, attributeValue: attributeValue ?? "" },
    { enabled: !!clusterId && !!attributeValue }
  );
  const { grid, position } = getClusterChartLayoutConfig();

  useEffect(() => {
    if (pvins && attributeValue) {
      setAttributeValue(undefined);
      setLoading(false);
      setAffectedPvins(pvins.filteredQuery.attributeCountPvins);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pvins]);

  const buildChartConfig = (data: AttributeCount[]) => {
    return {
      animation: false,
      tooltip: {
        trigger: "item",
        position,
        formatter: (params: { data: { name: string; value: number }; percent: number }) => {
          const { data, percent } = params;
          if (data) {
            const misHeader = `<th style='color: rgb(41.2%, 16.1%, 77.3%'); padding: 8px;'>Attribute</th>`;
            const totalCountHeader = "<th style='padding: 8px'>Vehicle Count</th>";
            const percentageFleetHeader = "<th style='padding: 8px'>Fleet Percentage</th>";
            const tableHead = `<tr>${misHeader}${totalCountHeader}${percentageFleetHeader}</tr>`;
            const tableRows = `
          <tr style="text-align: center">
            <td style='padding: 8px'>${data.name}</td>
            <td style='padding: 8px'>${data.value}</td>
            <td style='padding: 8px'>${percent}%</td>
          </tr>`;
            return `<table>${tableHead}${tableRows}</table>`;
          }
        },
      },
      grid,
      series: {
        type: "pie",
        data: data.map((d) => {
          const rawName = d.attribute;
          const displayName =
            attribute === AttributeTypeEnum.ModelType
              ? toModelDescription({ model: "", modelStyle: d.attribute }, customerIdentifier.models)
              : d.attribute;

          attributeMap.current.set(displayName || "", rawName);

          return {
            value: d.pvinCount,
            name: displayName,
          };
        }),
      },
    };
  };

  return (
    <BasicWidget
      title={
        <ClusterViewGraphHeader>
          <div>{toTitleCase("Vehicle Attributes")}</div>
          <Select
            className="attribute-select"
            options={AttributeOptions}
            defaultValue={attribute}
            onChange={(value) => {
              setAttribute(value);
            }}
          />
        </ClusterViewGraphHeader>
      }
    >
      <BaseEChart
        // This forces the two charts to be the same size since the fault has a legend
        style={{ marginTop: "25px" }}
        option={buildChartConfig(data?.filteredQuery.attributeCount ?? [])}
        showLoading={isFetching || isLoading}
        onEvents={{
          click: (e: { name: string }) => {
            setModalView("vehiclesAffected");
            setLoading(true);
            const rawName = attributeMap.current.get(e.name) || e.name;
            setAttributeValue(rawName);
          },
        }}
      />
    </BasicWidget>
  );
};
