import styled from "styled-components";

export const GlobalFilterContainer = styled.div`
  .filter-cta {
    padding: 1rem;
    display: flex;
    align-items: center;
    svg {
      color: ${({ theme }) => theme.colors.blueGray};
    }
    gap: 1rem;
    &.active {
      svg {
        color: ${({ theme }) => theme.colors.accentBlue};
      }
    }
    .ant-badge-count {
      background: ${({ theme }) => theme.colors.accentBlue};
    }
  }
`;
