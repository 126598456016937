import styled from "styled-components";

export const TableFilterContainer = styled.div`
  display: flex;
  padding: 1.5rem;
  gap: 0.5rem;
`;

export const VisualWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const CriteriaSelectorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .ant-radio-group {
    display: flex;
    .ant-radio-button-wrapper {
      flex: 1;
    }
  }
`;

export const FilterCriteriaOptionContainer = styled.div`
  font-family: "Inter";
  font-size: 0.85rem;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  svg {
    width: 2rem;
  }
`;

export const TableFilterDateRangesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .quick-dates-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
`;
