import IconSearch from "@amayaIcons/icon-search.svg";
import { useNavigate } from "@tanstack/react-router";
import { AutoComplete, Input } from "antd";
import _ from "lodash";
import React, { useMemo, useState } from "react";

import { FeatureId, useSearchAutoCompleteQuery } from "@/api";
import Loading from "@/components/loading";
import { UI_SETTINGS } from "@/constants";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { getCurrentCustomerId } from "@/utils/customers";
import { useFeatureFlags } from "@/utils/features";
import { FaultCodeDescription } from "@/utils/types/commonDataDictionary";

import { NavigationSearchBarContainer, SearchLabelTitle } from "./NavigationSearchBar.styled";

type SearchOptionElementProps = {
  prefix: string;
  label: string;
  suffix?: string;
};

const SearchOptionElement = ({ prefix, label, suffix }: SearchOptionElementProps) => {
  return (
    <>
      <span className="body-medium" style={{ color: "black" }}>
        <b>{prefix}</b> {label}
      </span>
      {suffix ? <span className="body-medium"> - {suffix}</span> : undefined}
    </>
  );
};

type SearchOption = {
  value: string;
  label: React.JSX.Element;
  disabled?: boolean;
};

export function NavigationSearchBar() {
  const navigate = useNavigate();

  const { customerIdentifier } = useCustomerIdentifier();

  const { isFeatureEnabled } = useFeatureFlags();

  const [search, setSearch] = useState("");

  const [isFocused, setIsFocused] = useState(false);

  const searchEnabled = search.length >= UI_SETTINGS.MIN_SEARCH_LENGTH;

  const uniqueFaultCodes = useMemo(() => {
    const faultCodesMap: { [key in string]: FaultCodeDescription } = {};
    customerIdentifier.faultCodes
      ?.filter((fc) => !!fc.faultCode)
      .forEach((faultCode) => faultCode.faultCode && (faultCodesMap[faultCode.faultCode] = faultCode));
    return Object.values(faultCodesMap);
  }, [customerIdentifier.faultCodes]);

  const {
    data: autoCompleteResults,
    isLoading,
    isRefetching,
  } = useSearchAutoCompleteQuery(
    {
      customerId: getCurrentCustomerId() ?? "",
      input: search,
    },
    { enabled: searchEnabled, keepPreviousData: true }
  );

  const searchIsLoading = isLoading || isRefetching;

  const handleSearch = _.debounce((searchTerm: string) => {
    setSearch(searchTerm);
  }, UI_SETTINGS.SEARCH_INPUT_DEBOUNCE);

  const autocompleteData = autoCompleteResults?.searchAutoComplete;

  /* Options are based on dictionary data, eventually the list of fault codes may be too large or not extensive of all existent fault codes.
     A more efficient solution (possibly hitting the api) should be built.
     https://github.com/venture-pull/amaya/pull/296/files/04cc8d5870756fe05776b9302677bf7f63a59097#r1357523145 */
  const options = useMemo(() => {
    if ((!search && !isFocused) || (searchEnabled && searchIsLoading)) {
      return [];
    }
    const clusterOptions =
      autocompleteData?.clusters?.map((cluster) => ({
        value: `/cluster/${cluster.id}`,
        label: (
          <SearchOptionElement
            prefix="Cluster"
            label={cluster.name}
            suffix={cluster.description ? cluster.description : undefined}
          />
        ),
      })) || [];
    const vehicleOptions =
      autocompleteData?.vehicleIds?.map(({ id, vin }) => ({
        value: `/vehicle/${id}`,
        label: <SearchOptionElement prefix="Vehicle" label={isFeatureEnabled(FeatureId.Vins) ? vin! : id} />,
      })) || [];
    const faultCodeOptions =
      uniqueFaultCodes
        ?.filter(
          (faultCode) =>
            faultCode.faultCode?.toLowerCase?.().includes(search.toLowerCase()) ||
            faultCode.name?.toLowerCase?.().includes(search.toLowerCase())
        )
        .map((faultCode) => ({
          value: `/fault/${faultCode.faultCode}`,
          label: <SearchOptionElement prefix="Fault code" label={faultCode.faultCode ?? ""} suffix={faultCode.name} />,
        })) ?? [];

    const allOptions: SearchOption[] = [...vehicleOptions, ...clusterOptions, ...faultCodeOptions];

    if (searchEnabled && !allOptions.length && !searchIsLoading) {
      return [
        { value: "No results found", label: <SearchLabelTitle>No results found</SearchLabelTitle>, disabled: true },
      ];
    } else if (!searchEnabled) {
      allOptions.unshift({
        value: "suggestions",
        label: <SearchLabelTitle>suggestions</SearchLabelTitle>,
        disabled: true,
      });
    }

    return allOptions;
  }, [uniqueFaultCodes, search, autocompleteData, isFocused, searchIsLoading, searchEnabled]);

  const onSelect = (value: string) => {
    navigate({ to: value });
  };

  return (
    <NavigationSearchBarContainer className="navigation-search-bar">
      <AutoComplete
        className="searchbar-autoselect"
        options={options}
        onSelect={onSelect}
        onChange={(newSearch) => handleSearch(newSearch.trim())}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        notFoundContent={searchIsLoading ? <Loading size="small" label="Searching..." /> : null}
      >
        <Input
          prefix={<IconSearch />}
          placeholder="Search for Events, Vehicles, and Clusters"
          className="searchbar-input body-medium"
        />
      </AutoComplete>
    </NavigationSearchBarContainer>
  );
}
