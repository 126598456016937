import React from "react";

import { Cluster } from "@/api";
import { EventFilter, FaultEvent } from "@/api/customerApi";
import { contentWithInlineLoading } from "@/components/ui/JsxUtils";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { getFaultDescription } from "@/utils/fault/getFaultDescription";

import { FaultEventHeaderInfoProps } from "./EventHeaderWidgetTypes";

const FaultEventHeaderInfo = ({ showCluster, clusterLoading, cluster, eventFilter }: FaultEventHeaderInfoProps) => {
  const { ecu, softwareVersion, softwarePartNumber, hardwarePartNumber, hardwareVersion, troubleCode } =
    eventFilter as FaultEvent;
  return (
    <div className="event-info-container">
      {ecu && (
        <div className="header-data-section">
          <p className="body-small">ECU</p>
          <p className="body-medium dark">{ecu}</p>
        </div>
      )}
      {softwareVersion && (
        <div className="header-data-section">
          <p className="body-small">Software Version</p>
          <p className="body-medium dark">{softwareVersion}</p>
        </div>
      )}
      {hardwareVersion && (
        <div className="header-data-section">
          <p className="body-small">Hardware Version</p>
          <p className="body-medium dark">{hardwareVersion}</p>
        </div>
      )}
      {softwarePartNumber && (
        <div className="header-data-section">
          <p className="body-small">Software Part Number</p>
          <p className="body-medium dark">{softwarePartNumber}</p>
        </div>
      )}
      {hardwarePartNumber && (
        <div className="header-data-section">
          <p className="body-small">Hardware Part Number</p>
          <p className="body-medium dark">{hardwarePartNumber}</p>
        </div>
      )}
      {troubleCode && (
        <div className="header-data-section">
          <p className="body-small">Trouble Code</p>
          <p className="body-medium dark">{troubleCode}</p>
        </div>
      )}
      {showCluster && (
        <div className="header-data-section">
          <p className="body-small">Cluster</p>
          <div className="body-medium dark">{contentWithInlineLoading(clusterLoading, cluster?.name ?? "")}</div>
        </div>
      )}
    </div>
  );
};

export const EventHeaderFault: React.FC<{
  eventFilter: EventFilter;
  cluster?: Cluster;
}> = ({ eventFilter, cluster }) => {
  const { customerIdentifier } = useCustomerIdentifier();

  const faultCodeLabel = eventFilter.fault?.faultCode.toUpperCase();

  const description = getFaultDescription(
    customerIdentifier,
    eventFilter.fault?.faultCode ?? "",
    eventFilter.fault?.ecu ?? "",
    eventFilter.fault?.troubleCode ?? ""
  );

  return (
    <>
      <h1 className="heading-normal header-event-label">
        <span>Fault Event</span>
        <span>{`${faultCodeLabel}${description?.name ? ` - ${description.name}` : ""}`}</span>
      </h1>
      <div className="header-event-data">
        <FaultEventHeaderInfo
          showCluster={!!cluster}
          cluster={cluster}
          clusterLoading={false}
          eventFilter={eventFilter.fault as FaultEvent}
        />
      </div>
    </>
  );
};
