import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from "@lib/src/table/defaults";
import { useNavigate } from "@tanstack/react-router";
import { TableProps } from "antd";
import { ColumnType } from "antd/es/table";

import { ColumnFilter, FilterType, GroupFilterCriteria, Pagination, SortFilter, SortOrder } from "@/api";

import { BasicTable, BasicTableProps } from "./BasicTable";
import { FilterHeader } from "./filter/FilterHeader";
import { TableStateSearchKey } from "./StatefulTable.types";

export type ColumnTypeWithFiltering<T extends object> = ColumnType<T> & {
  filterProps?: {
    label: string;
    unit?: string;
    isLoading: boolean;
    options: { value: string | undefined; label: string }[];
    onApplyFilter?: (fieldName: string, filter: ColumnFilter) => void;
    onSearchChange: (value: string) => void;
    type: FilterType;
    columnKey: string;
    criteria: GroupFilterCriteria;
    includeNulls: boolean;
    labelRenderer?: (value: string) => string;
  };
  tooltipMessage?: (value: any, record: any) => string | undefined;
};

const columnsConfigMapper = <DataType,>(
  columns?: ColumnType<DataType>[],
  sorting?: SortFilter
): ColumnType<DataType>[] => {
  return (
    columns?.map((c) => {
      if (c.key === sorting?.field) return { ...c, sortOrder: sorting?.order };
      return c;
    }) ?? []
  );
};

export type StatefulTableProps<T> = BasicTableProps<T> & {
  searchKey: TableStateSearchKey;
  pagination?: Pagination;
  sorting?: SortFilter;
  filters?: ColumnFilter[];
};

const StatefulTable = <T extends object>(props: StatefulTableProps<T>) => {
  const searchNavigate = useNavigate();

  const currentPagination: Pagination = {
    currentPage: props?.pagination?.currentPage || DEFAULT_CURRENT_PAGE,
    pageSize: props?.pagination?.pageSize || DEFAULT_PAGE_SIZE,
  };

  const currentSorting = props?.sorting;

  const currentFilters = props?.filters ?? [];

  const handleTableChange: TableProps<T>["onChange"] = (pagination, _filters, sorter) => {
    if (Array.isArray(sorter)) {
      console.warn("Unsupported usage of multiple sorting, ignoring it.");
      return;
    }

    const newSorting = sorter.columnKey
      ? {
          field: (sorter.columnKey ?? "").toString(),
          order: sorter.order as SortOrder,
        }
      : currentSorting;

    const newPagination: Pagination = {
      currentPage: pagination.current ?? currentPagination.currentPage,
      pageSize: pagination.pageSize ?? currentPagination.pageSize,
    };

    if (!props.searchKey) return;
    const searchKey = props.searchKey;

    searchNavigate({
      search: (prev) => ({
        ...prev,
        [searchKey]: {
          pagination: newPagination,
          sorting: newSorting,
          filters: currentFilters,
        },
      }),
    });
  };

  const handleFilterChange = (filters: ColumnFilter[]) => {
    if (!props.searchKey) return;
    const searchKey = props.searchKey;
    searchNavigate({
      search: (prev) => ({
        ...prev,
        [searchKey]: {
          pagination: { currentPage: 1, pageSize: currentPagination.pageSize },
          sorting: currentSorting,
          filters: filters,
        },
      }),
    });
  };

  const columnsWithSorting = columnsConfigMapper(props.columns, props.sorting);

  const withFiltering = props.columns?.some((c: ColumnTypeWithFiltering<T>) => !!c.filterProps);
  return (
    <>
      {withFiltering && (
        <FilterHeader
          columns={props.columns ?? []}
          handleFilterChange={handleFilterChange}
          initialValues={currentFilters}
        />
      )}
      <BasicTable
        {...props}
        dataSource={props.dataSource}
        columns={columnsWithSorting}
        onChange={handleTableChange}
        pagination={
          props.pagination
            ? {
                showSizeChanger: true,
                pageSizeOptions: [5, 10, 25, 50],
                current: props.pagination.currentPage,
                className: "stateful-table-pagination",
                ...props.pagination,
              }
            : undefined
        }
      />
    </>
  );
};

export default StatefulTable;
