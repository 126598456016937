import { EChartsInstance } from "echarts-for-react";
import { Dispatch, MutableRefObject, SetStateAction } from "react";

import { getBarColor } from "./AnomalyHistogramBarColorFunctions";
import { getLine, getLineHandleSvgs } from "./AnomalyHistogramGraphicFunctions";
import { AnomalyBarValues, AnomalyHistogramTextInputInfo, barName } from "./AnomalyHistogramPreview";
import {
  anomalyHistogramPreviewUtilityFunctions,
  constrainHistogramToolTip,
} from "./anomalyHistogramPreviewUtilityFunctions";
import { getBarTooltip, getThresholdTooltip } from "./AnomalyHistogramTooltip";

export const barNameMap = {
  lowerNormal: "Lower Normal Threshold",
  upperNormal: "Upper Normal Threshold",
  cautionBar: "Caution Threshold",
  warningBar: "Warning Threshold",
};

type graphicObj = {
  id: barName;
  name: string;
  position: [number, number];
  positionFn: (pos: [number, number]) => [number, number];
  shape: {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
  };
};

export type AnomalyHistogramPreviewLogicArgs = {
  min: number[];
  max: number[];
  pct: number[];
  setBarValues: Dispatch<SetStateAction<AnomalyBarValues | undefined>>;
  barValues: AnomalyBarValues;
  barValuesRef: MutableRefObject<AnomalyBarValues>;
  chartRef: MutableRefObject<any>;
  dragBarRef: MutableRefObject<barName | undefined>;
  maxPct: number;
  chartFunctions: ReturnType<typeof anomalyHistogramPreviewUtilityFunctions>;
  renderingOrder: string[];
  handleRenderOrder: (lastClickedBar: string) => void;
  inputInfo: AnomalyHistogramTextInputInfo | undefined;
  setInputInfo: Dispatch<SetStateAction<AnomalyHistogramTextInputInfo | undefined>>;
};
export const anomalyHistogramPreviewLogic = ({
  min,
  max,
  pct,
  setBarValues,
  chartRef,
  barValuesRef,
  barValues,
  dragBarRef,
  maxPct,
  chartFunctions,
  renderingOrder,
  handleRenderOrder,
  inputInfo,
  setInputInfo,
}: AnomalyHistogramPreviewLogicArgs) => {
  const { getChartValues, getBarIndex, getBarStartPos, getPixelPosOfValue, findExactValue, findExactValueFromBarName } =
    chartFunctions;

  const normalizeData = (val: number) => {
    // state doesnt update on drag so we need to do this to show the value is at 0 or 1
    if (dragBarRef.current === "lowerNormal") return 0;
    if (dragBarRef.current === "upperNormal") return 1;
    const minX = barValues.lowerNormal;
    const maxX = barValues.upperNormal;
    if (minX === maxX) return 1;
    return (val - minX) / (maxX - minX);
  };

  const getNormalValuesForXAxis = (dataIndex: number) => {
    if (barValues.lowerNormal === barValues.upperNormal) return "100";
    const reverseScaleCheck = (val: number) => {
      if (getBarIndex(barValues.cautionBar) >= getBarIndex(barValues.warningBar)) {
        return `${((1 - val) * 100).toFixed(2)}`;
      }
      return (val * 100).toFixed(2);
    };
    if (max[dataIndex] < min[getBarIndex(barValues.lowerNormal)]) return reverseScaleCheck(0);
    if (min[dataIndex] > max[getBarIndex(barValues.upperNormal)]) return reverseScaleCheck(1);
    const lowerVal = Math.max(0, normalizeData(min[dataIndex]));
    const upperVal = Math.min(1, normalizeData(max[dataIndex]));
    if (lowerVal === upperVal) return reverseScaleCheck(lowerVal);
    return `${reverseScaleCheck(lowerVal)} - ${reverseScaleCheck(upperVal)}`;
  };

  const createHistogramSeries = (pct: number[], bars: typeof barValues) => {
    return {
      id: "histogramId",
      name: "histogram",
      type: "bar",
      barWidth: "90%",
      stack: "total",
      data: pct.map((v, i) => ({
        value: v * 100,
        itemStyle: {
          color: getBarColor(i, bars, getChartValues, getPixelPosOfValue, getBarStartPos, findExactValue, min, max),
        },
      })),
    };
  };

  const chartConfig: EChartsInstance = () => {
    const bucketLabels = [...min.map((_, i) => `${min[i].toPrecision(5)}`)];

    return {
      option: {
        animation: false,
        title: {
          text: "Fleet Histogram",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          position: (
            point: [number, number],
            _params: any,
            _dom: any,
            _rect: any,
            size: { contentSize: [number, number]; viewSize: [number, number] }
          ) => {
            return constrainHistogramToolTip(point, size);
          },
          formatter: function (params: any) {
            if (dragBarRef.current) {
              return getThresholdTooltip(
                barNameMap[dragBarRef.current],
                findExactValueFromBarName(dragBarRef.current),
                normalizeData(Number(findExactValueFromBarName(dragBarRef.current))) * 100
              );
            }
            const index = params[0].dataIndex;
            return getBarTooltip(min[index], max[index], params[0].data.value, getNormalValuesForXAxis(index));
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [...new Set(bucketLabels)],
          axisLabel: {
            rotate: 45,
          },
        },
        yAxis: {
          type: "value",
          min: 0,
          max: maxPct,
        },
        series: [createHistogramSeries(pct, barValues)],
      },
    };
  };

  const handleBarMouseUp = (dragBarName: string) => {
    handleRenderOrder(dragBarName);
  };

  const handleBarDrag = (x: number, dragBarName: barName) => {
    if (!chartRef.current) return;
    const { chartZeroYPixel } = getChartValues();
    dragBarRef.current = dragBarName;
    const graphics = chartRef.current.getOption().graphic[0].elements;
    const graphic = graphics.find((g: any) => g.id === dragBarName);
    graphic.position = [constrainCoords(x, dragBarName), chartZeroYPixel];
    const newBarVals: Partial<typeof barValues> = {};
    const newGraphics = graphics.map((g: graphicObj) => {
      if (!isNaN(barValues[g.id])) {
        newBarVals[g.id] = Number(findExactValue(g.position[0]));
      } else if (g.name === graphic.name) {
        g.position = g.positionFn(graphic.position);
      }
      return g;
    });
    chartRef.current.setOption({
      graphic: newGraphics,
      series: [createHistogramSeries(pct, newBarVals as typeof barValues)],
    });
  };

  const handleBarDragEnd = () => {
    if (!chartRef.current) return;
    dragBarRef.current = undefined;
    const graphic = chartRef.current.getOption().graphic[0].elements;
    const newBarVals: Partial<typeof barValues> = {};
    graphic.forEach((g: graphicObj) => {
      if (!isNaN(barValues[g.id])) {
        newBarVals[g.id] = Number(findExactValue(g.position[0]));
      }
    });
    setBarValues({ ...barValues, ...newBarVals });
  };

  const handleBarDragFromMouseMovement = (mouseEvent: MouseEvent) => {
    if (!chartRef.current) return;
    const dragBarName = dragBarRef.current;
    if (!dragBarName) return;
    const graphics = chartRef.current.getOption().graphic[0].elements;
    const graphic = graphics.find((g: any) => g.id === dragBarName);
    handleBarDrag(graphic.position[0] + mouseEvent.movementX, dragBarName);
  };

  const removeEventListeners = () => {
    window.removeEventListener("mousemove", handleBarDragFromMouseMovement);
    window.removeEventListener("mouseup", removeEventListeners);
    handleBarDragEnd();
  };

  const setChartGraphics = () => {
    if (!chartRef.current) return;
    const chart = chartRef.current;
    const { chartZeroYPixel } = getChartValues();
    chart.setOption({
      series: [createHistogramSeries(pct, barValues)],
      graphic: Object.entries(barValues)
        .map(([key, value]) => {
          const mouseDownFn = () => {
            dragBarRef.current = key as keyof AnomalyBarValues;
            window.addEventListener("mousemove", handleBarDragFromMouseMovement);
            window.addEventListener("mouseup", removeEventListeners);
          };

          const positionFn = (
            point: [number, number],
            _params: any,
            _dom: any,
            _rect: any,
            size: { contentSize: [number, number]; viewSize: [number, number] }
          ) => {
            return constrainHistogramToolTip(point, size);
          };

          const formatterFn = (params: { name: barName }) => {
            if (inputInfo?.name === params.name) return undefined;
            const value = findExactValueFromBarName(params.name);
            const normalValue = (normalizeData(Number(value)) * 100).toFixed(2);
            return getThresholdTooltip(barNameMap[params.name], value, Number(normalValue));
          };

          const onClickFn = (e: any) => {
            const parent = document.getElementById("AnomalyHistogramPreview");
            if (parent) {
              const [x, y] = constrainHistogramToolTip([e.offsetX, e.offsetY], {
                contentSize: [260, 60],
                viewSize: [parent.clientWidth, parent.clientHeight],
              });
              setInputInfo({ x: x, y: y, name: e.target.name, value: findExactValueFromBarName(e.target.name) });
            }
          };

          const onDoubleClickFn = (e: any) => {
            setInputInfo(undefined);
            handleBarMouseUp(e.target.name);
          };

          const pos: [number, number] = [getPixelPosOfValue(value), chartZeroYPixel as number];
          return [
            ...getLineHandleSvgs(
              key,
              pos,
              renderingOrder,
              {
                onmousedown: mouseDownFn,
                onclick: onClickFn,
                ondblclick: onDoubleClickFn,
              },
              { position: positionFn, formatter: formatterFn }
            ),
            getLine({
              name: key,
              pos,
              renderingOrder,
              chart,
              maxPct,
              chartZeroYPixel,
              positionFn,
              formatterFn,
              onClickFn,
              onDoubleClickFn,
              handleBarDrag,
              handleBarDragEnd,
            }),
          ];
        })
        .flat(),
    });
  };

  const handleChartReady = (c: EChartsInstance) => {
    chartRef.current = c;
    let chart = chartRef.current;
    setChartGraphics();
    function updatePosition() {
      setTimeout(() => {
        if (chart.getOption()?.graphic) {
          const { chartZeroYPixel, dragBarOffset } = getChartValues();
          const graphic = chart.getOption().graphic[0].elements;
          chart.setOption({
            graphic: graphic.map((g: graphicObj) => {
              const coords = [getPixelPosOfValue(barValuesRef.current[g.id]), 0];
              g.shape = {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: chart.convertToPixel("grid", [0, maxPct])[1] - chartZeroYPixel,
              };
              g.position = [coords[0] - dragBarOffset, chartZeroYPixel];
              return g;
            }),
          });
          //   forces a rerender to make all the drag calculations work for new size
          setBarValues({ ...barValuesRef.current });
        }
      }, 100);
    }
    window.removeEventListener("resize", updatePosition);
    window.addEventListener("resize", updatePosition);
  };

  const constrainCoords = (x: number, bar: keyof typeof barValues) => {
    const { laneWidth, finalXPixel, chartZeroXPixel } = getChartValues();
    let lowerBound = chartZeroXPixel - laneWidth / 2;
    let upperBound = finalXPixel + laneWidth / 2;
    if (bar === "cautionBar") {
      lowerBound = getPixelPosOfValue(barValues.lowerNormal);
      upperBound = getPixelPosOfValue(barValues.warningBar);
    } else if (bar === "warningBar") {
      lowerBound = getPixelPosOfValue(barValues.cautionBar);
      upperBound = getPixelPosOfValue(barValues.upperNormal);
    } else if (bar === "lowerNormal") {
      upperBound = getPixelPosOfValue(barValues.cautionBar);
    } else {
      lowerBound = getPixelPosOfValue(barValues.warningBar);
    }
    return x < lowerBound + 1 ? lowerBound : x > upperBound ? upperBound : x;
  };

  return { handleChartReady, chartConfig: chartConfig(), setChartGraphics };
};
