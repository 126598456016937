import { Skeleton } from "antd";

import { MetricCardStyled, MetricCardTitle, MetricCardValue } from "./MetricCardStyled";

export type MetricCardProps = {
  title: string;
  value: string | number;
  isLoading?: boolean;
};

const MetricCard = ({ title, value, isLoading }: MetricCardProps) => {
  return (
    <MetricCardStyled>
      <MetricCardTitle className="heading-x-small">{title}</MetricCardTitle>
      <MetricCardValue className="heading-large">
        {isLoading ? <Skeleton.Input active size="large" /> : value}
      </MetricCardValue>
    </MetricCardStyled>
  );
};

export default MetricCard;
