import { useEffect, useRef, useState } from "react";

import { GlobalFilter } from "../globalFilter/GlobalFilter";
import GlobalFilterForm from "../globalFilter/GlobalFilterForm";
import { NavigationSearchBar } from "../searchBar/NavigationSearchBar";
import { GlobalHeaderStyled, ToolbarContainer } from "./GlobalHeader.styled";

export const GlobalHeader = () => {
  const headerRef = useRef<HTMLDivElement>(null);

  const [filterIsActive, setFilterIsActive] = useState(false);

  const headerClassName = `${filterIsActive ? "filter-active" : "filter-inactive"}`;

  const handleClickOutside = (event: MouseEvent) => {
    if (headerRef.current && !headerRef.current.contains(event.target as Node)) {
      setFilterIsActive(false);
    }
  };

  useEffect(() => {
    if (headerRef.current) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <GlobalHeaderStyled className={headerClassName} ref={headerRef}>
      <GlobalFilterForm setIsActive={setFilterIsActive} />
      <ToolbarContainer className="toolbar-container">
        <NavigationSearchBar />
        <GlobalFilter setIsActive={setFilterIsActive} />
      </ToolbarContainer>
    </GlobalHeaderStyled>
  );
};
