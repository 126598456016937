import { DEFAULT_PAGINATION } from "@lib/src/table/defaults";
import { createFileRoute } from "@tanstack/react-router";

import { SortOrder } from "@/api";
import { RepairEventsColumns } from "@/api/customerApi";
import { TableState } from "@/components/tables/StatefulTable.types";
import { RepairEvents } from "@/features/repairEvents/RepairEvents";
import AppMainLayout from "@/layouts/AppMainLayout";

import { setTitleBeforeLoad } from "./__root";

export type RepairEventsSearch = {
  repairEventsTable: TableState;
};

const defaultTableState: TableState = {
  pagination: DEFAULT_PAGINATION,
  sorting: { field: RepairEventsColumns.Id, order: SortOrder.Descend },
  filters: [],
};

export const Route = createFileRoute("/repair-events/")({
  component: () => (
    <AppMainLayout>
      <RepairEvents />
    </AppMainLayout>
  ),
  beforeLoad: () => setTitleBeforeLoad("Repair Events"),
  validateSearch: (search: Record<string, unknown>): RepairEventsSearch => {
    return {
      repairEventsTable: (search.repairEventsTable as TableState) || defaultTableState,
    };
  },
});
