import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { DEFAULT_CURRENT_PAGE } from "@lib/src/table/defaults";
import { Modal } from "antd";
import { isArray, uniqBy } from "lodash";
import { useContext, useEffect, useState } from "react";

import { ColumnFilter, FeatureId } from "@/api";
import {
  Pagination,
  RepairEventClusterMatch,
  RepairEventsColumns,
  useRepairEventsClusterMatchesQuery,
  useRepairEventsColumnByUserInputQuery,
  useRepairEventsQuery,
} from "@/api/customerApi";
import { ModalSubheading } from "@/components/modal/modalSubheading.styled";
import { compareText } from "@/components/tables/sorting";
import StatefulTable from "@/components/tables/StatefulTable";
import { useRemoteFiltering } from "@/components/tables/useRemoteFiltering";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import Page404 from "@/pages/404";
import { Route } from "@/routes/repair-events.index";
import { useFeatureFlags } from "@/utils/features";
import { crcHashString } from "@/utils/stringUtils";
import { spreadModelStyleOptions } from "@/utils/vehicleModel";

import { ClusterMatchesTable } from "../cluster/clusterMatches/ClusterMatchesTable";
import { getColumns } from "./getRepairEventColumns";

const fields = Object.values(RepairEventsColumns);

const transformFilterValues = (filter: ColumnFilter) => {
  if (filter.key === RepairEventsColumns.ModelStyle) {
    return { ...filter, group: spreadModelStyleOptions(filter.group ?? []) };
  }
  return filter;
};

export const RepairEvents = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const areVinsEnabled = isFeatureEnabled(FeatureId.Vins);

  const [selectedClusterMatches, setSelectedClusterMatches] = useState<RepairEventClusterMatch[]>();
  const { customerIdentifier } = useCustomerIdentifier();
  const { currentlySearching, setSearchedFilteringOptions, filteringOptions, handleFilterInputChange } =
    useRemoteFiltering({ fields });

  const { globalFilter } = useContext(GlobalFilterContext);

  const {
    pagination: currentPagination,
    filters,
    sorting: sortersInput,
  } = Route.useSearch({
    select: (search) => {
      return search.repairEventsTable;
    },
  });

  const pagination: Pagination = {
    currentPage: currentPagination?.currentPage || DEFAULT_CURRENT_PAGE,
    pageSize: currentPagination?.pageSize || DEFAULT_PAGE_SIZE,
  };

  const { data, isLoading, isFetching } = useRepairEventsQuery(
    {
      filter: globalFilter,
      pagination,
      sort: sortersInput,
      columnFilters: filters?.map(transformFilterValues),
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME, keepPreviousData: true }
  );

  const { data: currentFilteringOptionsData, isFetching: isFetchingFilteringOptions } =
    useRepairEventsColumnByUserInputQuery(
      {
        filter: globalFilter,
        column: currentlySearching?.field as RepairEventsColumns,
        columnFilters: filters?.map(transformFilterValues),
        input: isArray(currentlySearching?.content)
          ? { mappedValues: currentlySearching.content }
          : { rawInput: currentlySearching?.content },
      },
      { enabled: !!currentlySearching?.content }
    );

  const RepairEventIds: string[] = [...(data?.filteredQuery.RepairEvents.data ?? []).map((d) => d.id)].filter(
    (id): id is string => id !== null && id !== undefined
  );

  const { data: clusterMatches, isLoading: isClusterMatchesLoading } = useRepairEventsClusterMatchesQuery(
    {
      filter: globalFilter,
      RepairEventIds: RepairEventIds,
    },
    { enabled: !isLoading }
  );

  useEffect(() => {
    if (!currentlySearching) {
      return;
    }
    const newOptions = (
      (currentFilteringOptionsData?.filteredQuery.RepairEventColumnByUserInput as (string | undefined)[]) ?? []
    ).map((option) => ({
      value: option,
      label: option || "-",
    }));
    setSearchedFilteringOptions({ field: currentlySearching.field, options: newOptions });
  }, [setSearchedFilteringOptions, currentlySearching, currentFilteringOptionsData]);

  const getFilteringOptions = (fieldName: RepairEventsColumns) => {
    const availableOptions = filteringOptions[fieldName] as { value: string; label: string }[];
    const previouslyUnselectedValues = filters?.find((filter) => filter.key === fieldName);
    const previouslyUnselectedOptions = previouslyUnselectedValues?.group?.map((value) => ({
      value: value,
      label: value || "-",
    }));
    const options = [...(previouslyUnselectedOptions ?? []), ...availableOptions].sort((a, b) =>
      compareText(a.label || "-", b.label)
    );
    const uniqueOptions = uniqBy(options, "value");
    return uniqueOptions as { label: string; value: string | undefined }[];
  };

  const columns = getColumns({
    isFetchingFilteringOptions,
    getFilteringOptions,
    handleFilterInputChange,
    areVinsEnabled,
    customerIdentifier,
    clusterMatches,
    isClusterMatchesLoading,
    setSelectedClusterMatches,
  });

  if (!isLoading && !isFetching && !data) return <Page404 message="Repair Events not found" />;

  return (
    <BasicWidget className="table" title="Repair Events">
      <StatefulTable
        searchKey="repairEventsTable"
        tableLayout="auto"
        columns={columns.filter((c) => {
          if (isFeatureEnabled(FeatureId.AssignedRepairEvents)) {
            return true;
          }
          return c.key !== "clusterId";
        })}
        dataSource={data?.filteredQuery.RepairEvents.data}
        loading={isFetching}
        rowKey={(r) => crcHashString(JSON.stringify(r))}
        pagination={{
          currentPage: pagination.currentPage,
          pageSize: pagination.pageSize,
          total: data?.filteredQuery.RepairEvents.pagination?.totalCount,
        }}
        filters={filters}
        sorting={sortersInput}
      />
      <Modal
        open={!!selectedClusterMatches}
        onCancel={() => setSelectedClusterMatches(undefined)}
        footer={null}
        width={1000}
        title={<h4 className="heading-x-small">Cluster Recommendations</h4>}
      >
        <ModalSubheading>
          <p className="body-medium dark">
            Recommendations are generated based on factors such as failure components and fault codes, with matches
            indicating that the given workshop log resembles those in the target cluster.
          </p>
        </ModalSubheading>
        <ClusterMatchesTable clusters={selectedClusterMatches ?? []} />
      </Modal>
    </BasicWidget>
  );
};
