import { Alert, Flex, Switch, Typography } from "antd";
import _ from "lodash";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FeatureId } from "@/api";
import { Fault, useVehicleHistoricalDataAnalysisQuery } from "@/api/customerApi";
import BlockingLoading from "@/components/ui/BlockLoading";
import { QUERY_SETTINGS } from "@/constants";
import { ChartLibraryActions } from "@/contexts/ChartLibrarySlice";
import {
  FaultEventFilter,
  selectFaultFilters,
  selectIndependentVariable,
  selectLibrarySpan,
} from "@/contexts/ChartLibraryStore";
import SCVContext from "@/contexts/SCVContext";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { getFaultDescription } from "@/utils/fault/getFaultDescription";
import { useFeatureFlags } from "@/utils/features";

import { FaultCategoryElementsContainer } from "./LibraryFaultListSelector.styled";
import { LibraryItem } from "./LibraryItem";
import LibraryListWithSearch from "./LibraryListWithSearch";

interface FaultCategoryElementsProps {
  faults: Fault[];
  selectedFaultFilters: FaultEventFilter[];
  onFaultToggle: (active: boolean, fault: Fault) => void;
}

const FaultCategoryElements = ({ faults, selectedFaultFilters, onFaultToggle }: FaultCategoryElementsProps) => {
  const { customerIdentifier } = useCustomerIdentifier();

  return (
    <FaultCategoryElementsContainer>
      {faults.map((f) => {
        const isFaultSelected = selectedFaultFilters.some(
          (selectedFilter) => selectedFilter.code === f.code && selectedFilter.troubleCode === f.troubleCode
        );
        const faultDescription = getFaultDescription(customerIdentifier, f.code, f.source, f.troubleCode ?? "");
        return (
          <div className="fault-item-container" key={f.id}>
            <Flex gap={8} className="fault-info">
              <Flex vertical className="info-labels-container">
                <Typography.Text className="label">Fault Code:</Typography.Text>
                <Typography.Text className="label">Trouble Code:</Typography.Text>
                <Typography.Text className="label">Description:</Typography.Text>
              </Flex>
              <Flex vertical className="fault-description">
                <Typography.Text className="description" code>
                  {f.code}
                </Typography.Text>
                <Typography.Text className="description" type="secondary">
                  {f.troubleCode ?? "-"}
                </Typography.Text>
                <Typography.Text className="description" type="secondary">
                  {faultDescription?.name ?? "-"}
                </Typography.Text>
              </Flex>
            </Flex>
            <Switch
              className="library-item-switch"
              checked={isFaultSelected}
              onChange={(checked) => onFaultToggle(checked, f)}
            />
          </div>
        );
      })}
    </FaultCategoryElementsContainer>
  );
};

const LibraryFaultListSelector = () => {
  const { isFeatureEnabled } = useFeatureFlags();
  const isTelemetryEnabled = isFeatureEnabled(FeatureId.Telemetry);

  const { pvin } = useContext(SCVContext);
  const dispatch = useDispatch();
  const { faultFilters: selectedFaultFilters } = useSelector(selectFaultFilters);
  const [searchTerm, setSearchTerm] = useState("");

  const { independentVariable } = useSelector(selectIndependentVariable);
  const librarySpan = useSelector(selectLibrarySpan);

  const { data, isLoading } = useVehicleHistoricalDataAnalysisQuery(
    {
      vehicleId: pvin,
      independentVarInterval: librarySpan.intervalInput,
      independentVar: independentVariable,
      independentVarRange: librarySpan.rangeInput,
      withXAxis: !isTelemetryEnabled,
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );

  const icSearchTerm = searchTerm.toLowerCase();

  const faultList = (data?.vehicleFaultHistory?.data ?? []) as Fault[];
  const filteredFaultList = faultList.filter(
    (f) => f.code.toLowerCase().includes(icSearchTerm) || f.troubleCode?.toLowerCase().includes(icSearchTerm)
  );

  const faultECU = _.groupBy(filteredFaultList, "source");
  const faultECUCategories = Object.keys(faultECU);

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleFaultCategoryToggle = (selected: boolean, source: string) => {
    const faultFilters = faultList
      .filter((f) => f.source === source)
      .map((f) => ({ code: f.code, troubleCode: f.troubleCode ?? "", source: f.source })) satisfies FaultEventFilter[];
    if (selected) {
      return dispatch(ChartLibraryActions.addFaults({ faultFilters }));
    }
    dispatch(ChartLibraryActions.removeFaults({ faultFilters }));
  };

  const handleFaultToggle = (selected: boolean, fault: Fault) => {
    if (selected) {
      return dispatch(
        ChartLibraryActions.addFaults({
          faultFilters: [{ code: fault.code, troubleCode: fault.troubleCode ?? "", source: fault.source }],
        })
      );
    }
    dispatch(
      ChartLibraryActions.removeFaults({
        faultFilters: [{ code: fault.code, troubleCode: fault.troubleCode ?? "", source: fault.source }],
      })
    );
  };

  if (isLoading) return <BlockingLoading className="line with-margin" />;

  const ecuToggleItems = !_.isEmpty(faultECUCategories) ? (
    faultECUCategories.map((ecu) => {
      const currentEcuFilteredFaults = filteredFaultList.filter((f) => f.source === ecu);
      const uniqueFaults = _.uniqBy(currentEcuFilteredFaults, (f) => `${f["troubleCode"]}-${f["code"]}`);

      const selectedItems = selectedFaultFilters.filter((selectedFilter) =>
        uniqueFaults.some(
          (uniqueFault) =>
            uniqueFault.code === selectedFilter.code && uniqueFault.troubleCode === selectedFilter.troubleCode
        )
      );

      const plottedLabel = `${selectedItems.length}/${uniqueFaults.length}`;
      const isSelected = selectedItems.length === uniqueFaults.length;
      return (
        <LibraryItem
          key={ecu}
          label={ecu}
          subLabel={plottedLabel}
          plottedIndex={-1}
          onToggle={(selected) => handleFaultCategoryToggle(selected, ecu)}
          isSelected={isSelected}
        >
          <FaultCategoryElements
            faults={uniqueFaults}
            selectedFaultFilters={selectedItems}
            onFaultToggle={handleFaultToggle}
          />
        </LibraryItem>
      );
    })
  ) : (
    <Alert
      style={{ marginTop: "1rem" }}
      showIcon
      message={searchTerm.length ? `No faults found` : `No fault data`}
      type="warning"
    />
  );

  return (
    <LibraryListWithSearch searchPlaceholder="Search by the fault code" onSearch={handleSearch}>
      {ecuToggleItems}
    </LibraryListWithSearch>
  );
};

export default LibraryFaultListSelector;
