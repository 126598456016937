import styled from "styled-components";

export const ClusterChartButtonsContainer = styled.div`
  display: flex;
  flex-shrink: 1;
  min-width: 0;
  button {
    span {
      text-overflow: ellipsis;
    }
    margin: 5px;
    flex-shrink: 1;
    min-width: 0;
    display: flex;
    justify-content: center;
  }
`;
