import { DeleteOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { NotificationInstance } from "antd/lib/notification/interface";
import { useState } from "react";

import { useDeleteClusterMutation } from "@/api";
import { TableActionItem } from "@/components/tables/TableActions.styled";
import { getCurrentCustomerId } from "@/utils/customers";

export type DeleteClusterModalProps = {
  api: NotificationInstance;
  clusterId: string;
  clusterName?: string;
  onMutationStart: () => void;
  onMutationSuccess: () => void;
};

export const DeleteClusterModal = ({
  api,
  onMutationStart,
  onMutationSuccess,
  clusterId,
  clusterName,
}: DeleteClusterModalProps) => {
  const [deleteDialog, setDeleteDialog] = useState(false);

  const deleteClusterMutation = useDeleteClusterMutation();

  const deleteTitle = `Are you sure to delete ${clusterName ?? "this cluster"}?`;

  const handleDelete = () => {
    setDeleteDialog(false);
    onMutationStart();
    deleteClusterMutation.mutate(
      {
        customerId: getCurrentCustomerId() ?? "",
        clusterId,
      },
      {
        onSuccess: (data) => {
          const idDeleted = data.clusters?.deleteCluster;
          if (idDeleted) {
            api.success({ message: "Cluster successfully deleted" });
          }
          onMutationSuccess();
        },
      }
    );
  };

  return (
    <>
      <TableActionItem onClick={() => setDeleteDialog((prev) => !prev)}>
        <DeleteOutlined /> Delete
      </TableActionItem>
      <Modal
        title={<h3 className="heading-x-small">{deleteTitle}</h3>}
        okText="Yes"
        cancelText="No"
        onOk={handleDelete}
        onCancel={() => setDeleteDialog(false)}
        open={deleteDialog}
      >
        <span className="body-medium">This action cannot be reverted</span>
      </Modal>
    </>
  );
};
