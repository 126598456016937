import { Button } from "antd";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  svg {
    height: 1em;
    width: 1em;
  }
  path {
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  &:not(:disabled):hover {
    path {
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      fill: ${({ theme }) => theme.colors.antdHover};
      color: ${({ theme }) => theme.colors.antdHover};
    }
  }
`;
