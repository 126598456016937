import { Button } from "antd";
import { useState } from "react";

import { ClusterRedshiftCreateInput } from "@/api";
import { BasicTable } from "@/components/tables/BasicTable";
import { useVinLabel } from "@/hooks/useVinLabel";

import { ClusterModalMissingPvins } from "../clusterHome/ClusterHome.styled";

type MissingPvinsProps = {
  pvins: string[];
  handleDelete: (pvinsToDelete: ClusterRedshiftCreateInput[]) => void;
  isReadOnly: boolean;
};
export const ViewMissingPvins = ({ pvins, handleDelete, isReadOnly }: MissingPvinsProps) => {
  const [selectedPvins, setSelectedPvins] = useState<{ pvin: string }[]>([]);
  const [isDeletingPvins, setIsDeletingPvins] = useState(false);
  const vinLabel = useVinLabel();
  return (
    <>
      <h3>These {vinLabel}s are part of the cluster but are not found in our database</h3>
      <ClusterModalMissingPvins>
        <BasicTable<{ pvin: string }>
          rowSelection={
            !isReadOnly
              ? {
                  type: "checkbox",
                  onChange: (_selectedKeys, selectedRows) => {
                    setSelectedPvins(selectedRows);
                  },
                  columnWidth: 48,
                }
              : undefined
          }
          columns={[{ key: "pvin", title: "PVIN", dataIndex: "pvin" }]}
          dataSource={pvins.map((pvin) => ({ pvin }))}
          rowKey={(row) => row.pvin}
        />
      </ClusterModalMissingPvins>
      <div style={{ padding: "5px" }}>
        <Button
          danger
          type="text"
          disabled={!selectedPvins.length || isDeletingPvins}
          hidden={isReadOnly}
          loading={isDeletingPvins}
          onClick={() => {
            handleDelete(selectedPvins);
            setIsDeletingPvins(true);
          }}
        >
          Remove {vinLabel}s
        </Button>
      </div>
    </>
  );
};
