import { CloseOutlined } from "@ant-design/icons";
import React from "react";

import { PillStyled } from "./Pill.styled";

type PillProps = {
  onClick: (value: string) => void;
  value: string;
  label: React.ReactNode;
  selected: boolean;
  onClose?: (value: string) => void;
};

export const Pill = ({ label, value, selected, onClick, onClose }: PillProps) => {
  const className = selected ? "pill-selected" : "";
  return (
    <PillStyled className={className} onClick={() => onClick(value)}>
      {onClose && (
        <CloseOutlined
          className="pill-clear-cta"
          onClick={(e) => {
            e.stopPropagation();
            onClose(value);
          }}
        />
      )}
      {label}
    </PillStyled>
  );
};
