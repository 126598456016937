import { QuestionCircleOutlined } from "@ant-design/icons";
import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { isNotNullOrUndefined } from "@lib/src/isNotNullOrUndefined";
import { DEFAULT_CURRENT_PAGE } from "@lib/src/table/defaults";
import { Link, useSearch } from "@tanstack/react-router";
import { Card, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useContext } from "react";
import { useTheme } from "styled-components";

import { CalculatedStatus, FeatureId, Pagination } from "@/api";
import { AnomalyAffectedVehicle, useAnomalyAffectedVehiclesQuery } from "@/api/customerApi";
import StatefulTable from "@/components/tables/StatefulTable";
import FromNowFormatter from "@/components/ui/FromNowFormatter";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { useFeatureFlags } from "@/utils/features";
import { formatNumber, humanFormatNumber } from "@/utils/numberUtils";
import { humanFriendlyStatuses } from "@/utils/patterns";

import { AnomalyDetectorTitleContainer, AnomalyVehicleListContainer } from "./AnomalyVehicleListContainer.styled";

interface AnomalyOccurrencesProps {
  id: string;
  status: CalculatedStatus | undefined | null;
  showStatus: boolean;
}

const AnomalyVehicleList = ({ id, status = CalculatedStatus.Draft, showStatus = true }: AnomalyOccurrencesProps) => {
  const theme = useTheme();
  const { globalFilter } = useContext(GlobalFilterContext);

  const features = useFeatureFlags();

  const { customerIdentifier } = useCustomerIdentifier();

  const tableState = useSearch({ strict: false, select: (search) => search.anomalyDetectionsTable });

  const currentPagination: Pagination = {
    currentPage: tableState?.pagination?.currentPage || DEFAULT_CURRENT_PAGE,
    pageSize: tableState?.pagination?.pageSize || DEFAULT_PAGE_SIZE,
  };

  const currentSorting = tableState?.sorting;

  const { data, isLoading, isFetching } = useAnomalyAffectedVehiclesQuery(
    {
      anomalyId: id,
      anomalyStatus: status,
      pagination: currentPagination,
      sorting: currentSorting,
      filter: globalFilter,
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME, keepPreviousData: false }
  );

  const statusDescription = status ? humanFriendlyStatuses(status) : "-";

  const isCalculatingStatus = status === CalculatedStatus.Calculating || status === CalculatedStatus.Draft;

  const columns: ColumnsType<AnomalyAffectedVehicle> = [
    features.isFeatureEnabled(FeatureId.Vins)
      ? {
          key: "vin",
          title: "VIN",
          dataIndex: "vin",
          render: (vin, vehicle) => (
            <Link to="/vehicle/$pvin" params={{ pvin: vehicle.pvin }}>
              {vin}
            </Link>
          ),
          width: 150,
        }
      : {
          key: "pvin",
          title: "PVIN",
          dataIndex: "pvin",
          render: (pvin: string) => (
            <Link to="/vehicle/$pvin" params={{ pvin }}>
              {pvin}
            </Link>
          ),
          width: 150,
        },
    {
      key: "model",
      title: "Model Name",
      dataIndex: "model",
      width: 256,
      render: (model: string) => {
        return <div>{customerIdentifier.models?.model[model]?.default ?? model}</div>;
      },
    },
    {
      key: "modelStyle",
      title: "Model Style",
      dataIndex: "modelStyle",
      width: 128,
    },
    {
      key: "modelYear",
      title: "Model Year",
      dataIndex: "modelYear",
      width: 128,
    },
    {
      key: "score",
      title: "Score",
      dataIndex: "score",
      render: humanFormatNumber,
      sorter: true,
      width: 96,
    },
    {
      key: "lastDetected",
      title: "Score Age",
      dataIndex: "lastDetected",
      render: (lastDetected: string) => <FromNowFormatter value={new Date(lastDetected)} />,
      sorter: true,
      width: 128,
    },
  ];

  const vehicles = data?.filteredQuery?.anomalyAffectedVehicles?.data;
  const totalCount = data?.filteredQuery?.anomalyAffectedVehicles?.pagination.totalCount;

  const detectorDatasource = vehicles?.map((v) => ({
    ...v,
    score: isNotNullOrUndefined(v.score) ? v.score * 100 : undefined,
  }));

  return (
    <Card
      styles={{
        body: {
          padding: 0,
        },
        header: {
          padding: 0,
        },
      }}
      title={
        <AnomalyDetectorTitleContainer>
          <Typography.Title level={4} className="heading-large title">
            <b>{isCalculatingStatus ? statusDescription : formatNumber(totalCount)} Currently Anomalous Vehicles</b>
            <Tooltip
              title="List of vehicles with an anomaly score currently above Caution (yellow) or Warning (red) thresholds."
              placement="topLeft"
            >
              <QuestionCircleOutlined style={{ color: theme.colors.blueGray, fontSize: "1rem" }} />
            </Tooltip>
          </Typography.Title>
          {showStatus && (
            <Typography.Text type="secondary">
              Status: <strong>{statusDescription}</strong>
            </Typography.Text>
          )}
        </AnomalyDetectorTitleContainer>
      }
      actions={[]}
    >
      <AnomalyVehicleListContainer>
        <StatefulTable
          searchKey="anomalyDetectionsTable"
          dataSource={detectorDatasource}
          columns={columns}
          rowKey="pvin"
          loading={isLoading || isFetching}
          pagination={{
            total: totalCount,
            pageSize: currentPagination.pageSize,
            currentPage: currentPagination.currentPage,
          }}
          sorting={currentSorting}
          noDataText={status === CalculatedStatus.Calculating ? "Calculating Anomaly Detector Details…" : undefined}
        />
      </AnomalyVehicleListContainer>
    </Card>
  );
};

export default AnomalyVehicleList;
