import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { DEFAULT_CURRENT_PAGE } from "@lib/src/table/defaults";
import { Link } from "@tanstack/react-router";
import { ColumnType } from "antd/es/table";
import i18next from "i18next";
import { Dispatch, Key, SetStateAction, useContext } from "react";

import { Cluster } from "@/api";
import { ClusterMetadata, FeatureId, Pagination, useVehicleMetadataQuery } from "@/api/customerApi";
import StatefulTable from "@/components/tables/StatefulTable";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { Route } from "@/routes/cluster.$clusterId";
import { parseDateToStr } from "@/utils/dateUtils";
import { useFeatureFlags } from "@/utils/features";
import { toModelDescription } from "@/utils/vehicleModel";

type ClusterDiagnosticCheckTableProps = {
  clusterId: string;
  cluster: Cluster;
  setSelectedPvins: Dispatch<SetStateAction<ClusterMetadata[]>>;
  isReadOnly: boolean;
};

export const ClusterDiagnosticCheckTable = ({
  clusterId,
  setSelectedPvins,
  isReadOnly,
  cluster,
}: ClusterDiagnosticCheckTableProps) => {
  const { isFeatureEnabled } = useFeatureFlags();
  const areVinsEnabled = isFeatureEnabled(FeatureId.Vins);

  const { globalFilter } = useContext(GlobalFilterContext);
  const { customerIdentifier } = useCustomerIdentifier();
  const rowSelection = {
    onChange: (_newRowKeys: Key[], newSelectedPvins: ClusterMetadata[]) => {
      setSelectedPvins(newSelectedPvins);
    },
  };

  const { pagination: currentPagination } = Route.useSearch({
    select: (search) => {
      return search.clusterDiagnosticChecksTable;
    },
  });

  const pagination: Pagination = {
    currentPage: currentPagination?.currentPage || DEFAULT_CURRENT_PAGE,
    pageSize: currentPagination?.pageSize || DEFAULT_PAGE_SIZE,
  };

  const { isFetching: isFetchingVehiclesData, data } = useVehicleMetadataQuery({
    filter: globalFilter,
    clusterId: clusterId,
    pagination,
  });

  const columns: ColumnType<ClusterMetadata>[] = [
    {
      key: "maintenanceHistoryId",
      title: i18next.t("columnTitle.REPAIR_EVENT_ID"),
      dataIndex: "maintenanceHistoryId",
      hidden: cluster.recordWithMaintenanceHistoryCount === 0,
      render: (_, metadata) => metadata.maintenanceHistoryId || "-",
    },
    areVinsEnabled
      ? {
          key: "vin",
          title: "VIN",
          dataIndex: "vin",
          render: (_: any, row: ClusterMetadata) => {
            return (
              <Link to="/vehicle/$pvin" params={{ pvin: row.pvin }}>
                {row.vin}
              </Link>
            );
          },
        }
      : {
          key: "pvin",
          title: "PVIN",
          dataIndex: "pvin",
          render: (_: any, row: ClusterMetadata) => {
            return (
              <Link to="/vehicle/$pvin" params={{ pvin: row.pvin }}>
                {row.pvin}
              </Link>
            );
          },
        },
    {
      key: "modelYear",
      title: "Model Year",
      dataIndex: "modelYear",
    },
    {
      key: "modelType",
      title: "Model Style",
      dataIndex: "modelType",
      render: (_, metadata) => {
        if (!metadata.modelType) return "-";
        return (
          toModelDescription(
            {
              model: metadata.model ?? "",
              modelStyle: metadata.modelStyle ?? "",
            },
            customerIdentifier.models
          ) ?? "-"
        );
      },
    },
    {
      key: "startDate",
      title: "Workshop Start Date",
      dataIndex: "startDate",
      render: (_: any, row: ClusterMetadata) => {
        return row.startDate ? parseDateToStr(new Date(row.startDate), false, true) : "N/A";
      },
    },
    {
      key: "endDate",
      title: "Workshop End Date",
      dataIndex: "endDate",
      render: (_: any, row: ClusterMetadata) => {
        return row.endDate ? parseDateToStr(new Date(row.endDate), false, true) : "N/A";
      },
    },
    {
      key: "componentLocationId",
      title: `${i18next.t("columnTitle.COMPONENT_LOCATION_ID")}`,
      dataIndex: "componentLocationId",
      hidden: cluster.recordWithMaintenanceHistoryCount === 0,
      render: (_, metadata) => metadata.componentLocationId || "-",
    },
    {
      key: "diagnosticCheckId",
      title: i18next.t("columnTitle.DIAGNOSTIC_CHECK_ID"),
      dataIndex: "diagnosticCheckId",
      render: (_, metadata) => metadata.diagnosticCheckId || "-",
    },
  ];

  return (
    <StatefulTable<ClusterMetadata>
      searchKey="clusterDiagnosticChecksTable"
      rowSelection={
        !isReadOnly
          ? {
              type: "checkbox",
              columnWidth: 48,
              ...rowSelection,
            }
          : undefined
      }
      pagination={{
        total: data?.filteredQuery?.clusterMetadata?.pagination.totalCount,
        pageSize: pagination.pageSize,
        currentPage: pagination.currentPage,
      }}
      rowKey={(v) => `${v.pvin}-${v.startDate}-${v.endDate}`}
      loading={isFetchingVehiclesData}
      columns={columns}
      dataSource={data?.filteredQuery?.clusterMetadata.data ?? []}
    />
  );
};
