import { InputNumber } from "antd";
import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

import { FilterSelection, RangeFilter } from "./FilterHeader";

type TableFilterRangesProps = {
  setLocalSelections: Dispatch<SetStateAction<FilterSelection>>;
  localSelection: FilterSelection;
  label: string;
  unit?: string;
};

export const TableFilterRanges = ({ localSelection, setLocalSelections, unit, label }: TableFilterRangesProps) => {
  const values = localSelection?.values[0]?.value as RangeFilter;

  const buildLabel = (min: number | undefined, max: number | undefined) => {
    const u = unit ?? "";
    if (min === undefined && max !== undefined) {
      return `${label} <= ${max}${u}`;
    } else if (min !== undefined && max === undefined) {
      return `${label} >= ${min}${u}`;
    } else if (min !== undefined && max !== undefined) {
      if (min > max) {
        return "Invalid range";
      }
      return `${min}${u} - ${max}${u}`;
    }
    return "";
  };

  const handleChange = (min: number | undefined, max: number | undefined) => {
    if (min === undefined && max === undefined) {
      setLocalSelections({ ...localSelection, values: [] });
    } else {
      setLocalSelections({
        ...localSelection,
        values: [
          {
            label: buildLabel(min, max),
            value: { start: min, end: max },
          },
        ],
      });
    }
  };

  return (
    <TableFilterRangeWrapper>
      <InputNumber
        type="number"
        value={values?.start}
        onChange={(v) => {
          handleChange(v === null ? undefined : v, values?.end);
        }}
        prefix={"Min: "}
      />
      <InputNumber
        type="number"
        value={values?.end}
        prefix={"Max: "}
        onChange={(v) => {
          handleChange(values?.start, v === null ? undefined : v);
        }}
      />
    </TableFilterRangeWrapper>
  );
};

const TableFilterRangeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  .ant-input-number-affix-wrapper {
    width: 45%;
    margin-right: 10px;
  }
`;
