import { Button, Col, Row } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { FilterType, GroupFilterCriteria } from "@/api";

import { ColumnTypeWithFiltering } from "../StatefulTable";
import { FilterSelection, RangeFilter } from "./FilterHeader";
import { TableFilterDateRanges } from "./TableFilterDateRanges";
import { TableFilterRanges } from "./TableFilterRanges";
import { TableGroupFilter } from "./TableGroupFilter";

type TableFilterProps<T extends Object> = {
  label: string;
  isLoading: boolean;
  fieldName: T;
  options: { value: string | undefined; label: string }[];
  onApplyInclusions: (fieldName: T, entries: FilterSelection) => void;
  onSearchChange: (value: string) => void;
  type: FilterType;
  position?: DOMRect | undefined;
  seededLocalSelection?: FilterSelection;
  unit?: string;
} & ColumnTypeWithFiltering<T>["filterProps"];

export const TableFilterDialogue = <T extends Object>({
  label,
  isLoading,
  fieldName,
  options,
  onApplyInclusions,
  onSearchChange,
  seededLocalSelection,
  type,
  unit,
  labelRenderer,
}: TableFilterProps<T>) => {
  const [localSelections, setLocalSelections] = useState<FilterSelection>({
    values: [],
    criteria: GroupFilterCriteria.Incl,
    includeNulls: false,
  });
  const [search, setSearch] = useState("");
  const [selectOpen, setSelectOpen] = useState(false);

  useEffect(() => {
    if (seededLocalSelection) {
      if (labelRenderer) {
        const newValues = seededLocalSelection.values.map((v) => ({
          value: v.value,
          label: labelRenderer((v.value as string) ?? ""),
        }));
        setLocalSelections({ ...seededLocalSelection, values: newValues });
      } else {
        setLocalSelections(seededLocalSelection);
      }
    }
  }, [seededLocalSelection]);

  const handleSearchChange = (value: string) => {
    setSearch(value);
    if (value.trim()) {
      onSearchChange(value);
    }
    setSelectOpen(true);
  };

  const handleSelect = (selection: FilterSelection["values"][number]) => {
    const newSelections = [
      // prevents the same selection from being selected multiple times
      ...(type === FilterType.Group ? localSelections.values.filter((s) => s.value !== selection.value) : []),
      selection,
    ];
    setLocalSelections({ ...localSelections, values: newSelections });
    setSearch("");
    setSelectOpen(false);
    onSearchChange("");
  };

  const handleClearAllBtnClick = () => {
    setLocalSelections({ values: [], criteria: GroupFilterCriteria.Incl, includeNulls: false });
  };

  const handleApplyBtnClick = () => {
    onApplyInclusions(fieldName, localSelections);
  };

  const getOptions = () => {
    return options.filter(
      (o) =>
        o.label.toLowerCase().includes(search.toLowerCase()) &&
        !localSelections.values.map((ls) => ls.value).includes(o.value)
    );
  };

  const getSectionTitle = () => {
    if (type === FilterType.Group) {
      if (localSelections.criteria === GroupFilterCriteria.Incl) {
        return "Current Selections";
      } else {
        return "Current Exclusions";
      }
    } else {
      return "Selected Range";
    }
  };

  const getNoSelectionValue = () => {
    if (type === FilterType.Group) {
      if (localSelections.criteria === GroupFilterCriteria.Incl) {
        return "All values selected";
      } else {
        return "No values excluded";
      }
    } else {
      return "No range selected";
    }
  };

  const isButtonDisabled = () => {
    if (type == FilterType.Range || FilterType.Date) {
      if (localSelections.values?.[0]?.value) {
        const values = localSelections.values[0].value as RangeFilter;
        if (values.start !== undefined && values.end !== undefined && values.start > values.end) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <VisualWrapper>
      {type === FilterType.Range && (
        <TableFilterRanges
          setLocalSelections={setLocalSelections}
          localSelection={localSelections}
          label={label}
          unit={unit}
        />
      )}
      {type === FilterType.Group && (
        <TableGroupFilter
          label={label}
          localSelections={localSelections}
          setLocalSelections={setLocalSelections}
          search={search}
          selectOpen={selectOpen}
          setSelectOpen={setSelectOpen}
          isLoading={isLoading}
          handleSearchChange={handleSearchChange}
          handleSelect={handleSelect}
          getOptions={getOptions}
        />
      )}
      {type === FilterType.Date && <TableFilterDateRanges onSelect={(value) => handleSelect(value)} />}
      <h3 className="heading-xx-small">{getSectionTitle()}</h3>
      <Row>
        {!localSelections.values.length && <span className="body-medium">{getNoSelectionValue()}</span>}
        {!!localSelections.values.length &&
          localSelections.values.map((selection) => (
            <Col
              className="body-medium"
              key={`${selection.value}`}
              onClick={(e) => {
                e.stopPropagation();
                setLocalSelections({
                  ...localSelections,
                  values: [...localSelections.values.filter((s) => s.value !== selection.value)],
                });
              }}
            >
              {selection.label ?? "-"}
            </Col>
          ))}
      </Row>
      <ActionsButtonsWrapper>
        <Button onClick={handleClearAllBtnClick} disabled={!localSelections.values.length}>
          Clear Selections
        </Button>
        <Button type="primary" onClick={handleApplyBtnClick} disabled={isButtonDisabled()}>
          Apply
        </Button>
      </ActionsButtonsWrapper>
    </VisualWrapper>
  );
};

const VisualWrapper = styled.div`
  z-index: 101;
  background: white;
  width: 400px;
  padding: 1rem;
  .ant-select-single {
    width: 100%;
  }
  h3 {
    margin: 10px 0px;
  }
  .ant-row {
    max-width: 400px;
    max-height: 500px;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .ant-col {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    padding: 5px;
    border-radius: 5px;
  }
  .ant-col:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.hoverRow};
  }
`;

const ActionsButtonsWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
`;
