import { Link } from "@tanstack/react-router";

import { Fault } from "@/api/customerApi";
import TableActions from "@/components/tables/TableActions";
import { TableActionItem } from "@/components/tables/TableActions.styled";

type FaultHistoryAnalysisLinkProps = {
  fault: Fault;
};

const FaultHistoryActions = ({ fault }: FaultHistoryAnalysisLinkProps) => {
  return (
    <TableActions
      actionTitle="View in Event Analysis"
      menu={[
        {
          key: "code",
          label: (
            <TableActionItem>
              <Link to="/fault/$faultCodeId" params={{ faultCodeId: fault.code }}>
                Fault Code
              </Link>
            </TableActionItem>
          ),
        },
        {
          key: "code_ecu",
          label: (
            <TableActionItem>
              <Link
                to="/fault/$faultCodeId"
                params={{ faultCodeId: fault.code }}
                search={{
                  eventFilter: {
                    ecu: fault.source,
                  },
                }}
              >
                ECU and Fault Code
              </Link>
            </TableActionItem>
          ),
        },
        {
          key: "code_ecu_troublecode",
          label: (
            <TableActionItem>
              <Link
                to="/fault/$faultCodeId"
                params={{ faultCodeId: fault.code }}
                search={{
                  eventFilter: {
                    ecu: fault.source,
                    troubleCode: fault.troubleCode ?? undefined,
                  },
                }}
              >
                ECU, Fault Code, and Trouble Code
              </Link>
            </TableActionItem>
          ),
        },
      ]}
    />
  );
};

export default FaultHistoryActions;
