import styled from "styled-components";

export const FleetIntelContainer = styled.div`
  height: 486px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .fleet-intel-map {
    width: 100%;
    .fleet-map-chart {
      height: 100% !important;
    }
  }
  .fleet-intel-table {
    width: 100%;
    min-width: 510px;
    max-width: 650px;
    overflow-y: auto;
    box-shadow: 0px 1px 6px 0px #00000026;
    z-index: 2;
    .basic-table-wrapper {
      height: 100%;
    }
  }
`;

export const FleetIntelHeaderContainer = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 78px;
`;
