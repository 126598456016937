import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { DEFAULT_CURRENT_PAGE } from "@lib/src/table/defaults";
import { useContext } from "react";

import { Pagination } from "@/api";
import { AnomalyDetectorWithVehicleData, useAnomalySummaryQuery } from "@/api/customerApi";
import StatefulTable from "@/components/tables/StatefulTable";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { Route } from "@/routes/anomaly-detector.index";

import { AnomalyDetectorListContainer } from "./AnomalyDetectorHome.styled";
import { anomalySummaryCommonColumns } from "./anomalySummaryCommonColumns";
import { anomalySummaryExtraColumns } from "./anomalySummaryExtraColumns";

export const fromAnomaliesTableStateValue = "anomalies-table";

export const AnomalySummaryTable = () => {
  const { globalFilter } = useContext(GlobalFilterContext);

  const tableState = Route.useSearch({ select: (search) => search.anomaliesTable });

  const currentPagination: Pagination = {
    currentPage: tableState?.pagination?.currentPage || DEFAULT_CURRENT_PAGE,
    pageSize: tableState?.pagination?.pageSize || DEFAULT_PAGE_SIZE,
  };

  const currentSorting = tableState?.sorting;

  const { data, isFetching } = useAnomalySummaryQuery(
    {
      filter: globalFilter,
      pagination: currentPagination,
      sorting: currentSorting,
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );

  const columns = [...anomalySummaryCommonColumns, ...anomalySummaryExtraColumns];
  const total = data?.filteredQuery?.anomaliesWithVehicleCount?.pagination?.totalCount;
  const anomalies = (data?.filteredQuery?.anomaliesWithVehicleCount?.items || []) as AnomalyDetectorWithVehicleData[];

  return (
    <AnomalyDetectorListContainer>
      <StatefulTable
        searchKey="anomaliesTable"
        dataSource={anomalies}
        columns={columns}
        pagination={{
          total,
          pageSize: currentPagination.pageSize,
          currentPage: currentPagination.currentPage,
        }}
        sorting={currentSorting}
        loading={isFetching}
        rowKey={(row) => row.detector.id}
      />
    </AnomalyDetectorListContainer>
  );
};
