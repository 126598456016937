import { PlusOutlined } from "@ant-design/icons";
import { Popover, Space } from "antd";
import { useEffect, useState } from "react";

import { ColumnFilter, FilterType, GroupFilterCriteria } from "@/api";
import { Pill } from "@/components/pill/Pill";
import { parseDateToStr } from "@/utils/dateUtils";

import { ColumnTypeWithFiltering } from "../StatefulTable";
import {
  AddFilterWrapper,
  FilterHeaderWrapper,
  FilterOptionWrapper,
  FilterSelector,
  SelectedFiltersWrapper,
  SelectedFilterWrapper,
} from "./FilterHeader.styled";
import { TableFilterDialogue } from "./TableFilterDialogue";

type FilterHeaderProps<T extends object> = {
  columns: ColumnTypeWithFiltering<T>[];
  handleFilterChange: (filters: ColumnFilter[]) => void;
  initialValues: ColumnFilter[];
};

export type RangeFilter = {
  start: number | undefined;
  end: number | undefined;
};

export type FilterSelection = {
  values: { label: string; value: string | RangeFilter | undefined }[];
  criteria: GroupFilterCriteria;
  includeNulls: boolean;
};

type ActiveFilters = Record<string, FilterSelection>;

const seedInitialValues = <T extends object>(filters: ColumnFilter[], columns: ColumnTypeWithFiltering<T>[]) => {
  const activeFilters: ActiveFilters = {};
  filters.forEach((filter) => {
    const column = columns.find((col) => col.key === filter.key);
    const values =
      filter.type === FilterType.Group
        ? filter.group?.map((g) => ({ value: g, label: column?.filterProps?.labelRenderer?.(g) ?? g })) ?? []
        : [
            {
              label: `${parseDateToStr(new Date(filter?.range?.[0] ?? 0), false, true)} - ${parseDateToStr(
                new Date(filter?.range?.[1] ?? 0),
                false,
                true
              )}`,
              value: { start: filter?.range?.[0] ?? 0, end: filter?.range?.[1] ?? 0 },
            },
          ];
    activeFilters[filter.key] = {
      values,
      criteria: filter.groupCriteria ?? GroupFilterCriteria.Incl,
      includeNulls: !!filter.includeNulls,
    };
  });
  return activeFilters;
};

export const FilterHeader = <T extends object>({
  columns,
  handleFilterChange,
  initialValues,
}: FilterHeaderProps<T>) => {
  const [select, setSelect] = useState<boolean>(false);
  const [filters, setFilters] = useState<ActiveFilters>(seedInitialValues(initialValues, columns));
  const [selectedFilter, setSelectedFilter] = useState<ColumnTypeWithFiltering<T>>();

  useEffect(() => {
    setFilters(seedInitialValues(initialValues, columns));
  }, [initialValues]);

  const handleClick = (column: ColumnTypeWithFiltering<T>) => {
    if (column.filterProps?.columnKey ?? "") {
      setSelectedFilter(column);
      if (!filters[column.filterProps?.columnKey ?? ""]) {
        setFilters({
          ...filters,
          [column.filterProps?.columnKey ?? ""]: {
            values: [],
            criteria: column.filterProps?.criteria ?? GroupFilterCriteria.Incl,
            includeNulls: column.filterProps?.includeNulls ?? false,
          },
        });
      }
    }
    setSelect(false);
  };

  const handleApplyFilters = (fieldName: string, entries: FilterSelection) => {
    const newFilters = { ...filters };
    newFilters[fieldName] = entries;
    setFilters(newFilters);
  };

  const handlePillClick = (column: ColumnTypeWithFiltering<T> | undefined) => {
    setSelect(false);
    setSelectedFilter(column);
  };

  const handleSelectPillClick = () => {
    setSelectedFilter(undefined);
    setSelect(true);
  };

  const handlePillClose = (filterKey: string) => {
    const newFilters = { ...filters };
    delete newFilters[filterKey];
    setFilters(newFilters);
    setSelectedFilter(undefined);
    const columnFilters = Object.entries(newFilters).map(([fieldName, filter]) =>
      convertToColumnFilter(fieldName, filter)
    );
    handleFilterChange(columnFilters);
    const column = columns.find((c) => c.key === selectedFilter?.key);
    column?.filterProps?.onApplyFilter?.(filterKey, {
      key: column.filterProps.columnKey,
      group: [],
      range: [],
      includeNulls: column.filterProps.includeNulls,
      groupCriteria: column.filterProps.criteria,
      type: column.filterProps.type,
    });
  };

  const buildPillLabel = (column: ColumnTypeWithFiltering<T> | undefined) => {
    const { values, criteria } = filters[column?.filterProps?.columnKey ?? ""];
    let valLabel = <div></div>;
    if (!values) return "";
    const labelRenderer = column?.filterProps?.labelRenderer;
    if (criteria === GroupFilterCriteria.Excl) {
      if (values.length === 1) {
        valLabel = <s>{labelRenderer ? labelRenderer(values[0].value as string) : values[0].label}</s>;
      } else if (values.length > 1) {
        valLabel = <span>{values.length} Excluded</span>;
      }
    } else {
      if (values.length === 1) {
        valLabel = <span>{labelRenderer ? labelRenderer(values[0].value as string) : values[0].label}</span>;
      } else if (values.length > 1) {
        valLabel = <span>{values.length} Included</span>;
      }
    }
    const label = (
      <Space>
        {`${column?.title} `}
        {valLabel}
      </Space>
    );
    return label;
  };

  const convertToColumnFilter = (fieldName: string, selection: FilterSelection) => {
    const column = columns.find((c) => c.filterProps?.columnKey === fieldName);
    const group = column?.filterProps?.type === FilterType.Group ? selection.values.map((v) => v.value as string) : [];
    const range =
      (column?.filterProps?.type === FilterType.Range || column?.filterProps?.type === FilterType.Date) &&
      selection.values.length
        ? [(selection.values[0].value as RangeFilter).start, (selection.values[0].value as RangeFilter).end]
        : [];
    const filter = {
      key: column?.filterProps?.columnKey ?? "",
      group: group,
      range: range,
      includeNulls: selection.includeNulls,
      groupCriteria: selection.criteria,
      type: column?.filterProps?.type ?? FilterType.Group,
    };
    return filter as ColumnFilter;
  };

  return (
    <FilterHeaderWrapper>
      <SelectedFiltersWrapper onClick={(e) => e.stopPropagation()}>
        {Object.keys(filters).map((f) => {
          const column = columns.find((c) => c.filterProps?.columnKey === f);
          if (!column || !column.filterProps) return <></>;
          return (
            <SelectedFilterWrapper
              key={column?.key}
              id={`selected-filter-pill-${f}`}
              className="selected-filter-wrapper"
            >
              <Popover
                trigger="click"
                placement="bottomLeft"
                open={selectedFilter?.key === column.key}
                onOpenChange={(visible) => {
                  if (!visible) setSelectedFilter(undefined);
                }}
                content={
                  <TableFilterDialogue
                    {...column.filterProps}
                    fieldName={column?.filterProps?.columnKey ?? ""}
                    onApplyInclusions={(fieldName, entries) => {
                      handleApplyFilters(
                        fieldName ?? "",
                        entries ?? { values: [], criteria: GroupFilterCriteria.Incl, includeNulls: false }
                      );
                      setSelectedFilter(undefined);
                      const newFilters = { ...filters, [fieldName]: { ...filters[fieldName], ...entries } };
                      const columnFilters = Object.entries(newFilters).map(([fieldName, filter]) =>
                        convertToColumnFilter(fieldName, filter)
                      );
                      handleFilterChange(columnFilters);
                      column?.filterProps?.onApplyFilter?.(
                        fieldName,
                        convertToColumnFilter(fieldName, newFilters[fieldName])
                      );
                    }}
                    seededLocalSelection={filters[column.filterProps?.columnKey ?? ""]}
                  />
                }
              >
                <Pill
                  label={buildPillLabel(column)}
                  onClick={() => handlePillClick(column)}
                  value={f}
                  selected={!!initialValues.some((filter) => filter.key === f)}
                  onClose={() => handlePillClose(f)}
                />
              </Popover>
            </SelectedFilterWrapper>
          );
        })}
        <SelectedFilterWrapper id={`selected-filter-pill-select`} onClick={handleSelectPillClick}>
          <Popover
            trigger="click"
            placement="bottomLeft"
            open={select}
            onOpenChange={(visible) => {
              if (!visible) setSelect(false);
            }}
            content={
              <FilterSelector>
                <h3 className="heading-xx-small" style={{ paddingLeft: "10px" }}>
                  Filter By...
                </h3>
                {columns
                  .filter((c) => c.filterProps !== undefined)
                  .map((c) => (
                    <FilterOptionWrapper
                      className="body-medium"
                      key={c.filterProps?.columnKey}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedFilter(undefined);
                        handleClick(c);
                      }}
                    >
                      {`+ ${c.title}`}
                    </FilterOptionWrapper>
                  ))}
              </FilterSelector>
            }
          >
            <AddFilterWrapper>
              <PlusOutlined className="add-filter-cta-icon" /> Add Filter
            </AddFilterWrapper>
          </Popover>
        </SelectedFilterWrapper>
      </SelectedFiltersWrapper>
    </FilterHeaderWrapper>
  );
};
