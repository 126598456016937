import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { DEFAULT_CURRENT_PAGE } from "@lib/src/table/defaults";
import { Link, useNavigate } from "@tanstack/react-router";
import { isArray } from "lodash";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

import { Cluster, FilterType, GroupFilterCriteria, Pagination } from "@/api";
import {
  AffectedVehicleAttributes,
  FaultHistoryColumn,
  FaultsByPvinsAffectedPvinsArgs,
  FaultsByPvinsAffectedPvinsSortInput,
  FaultStat,
  useFaultHistoryColumnByUserInputQuery,
  useFaultsByPvinsAffectedPvinsQuery,
  useFaultsByPvinsQuery,
} from "@/api/customerApi";
import TableActions from "@/components/tables/TableActions";
import { TableActionItem } from "@/components/tables/TableActions.styled";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { DefinedView } from "@/features/cluster/clusterModal/ClusterModal";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { FaultEventAnalysisSearchFilter } from "@/hooks/useEventAnalysisSearch";
import { Route } from "@/routes/cluster.$clusterId";
import { getFaultDescription } from "@/utils/fault/getFaultDescription";

import { compareText } from "../sorting";
import StatefulTable, { ColumnTypeWithFiltering } from "../StatefulTable";
import { useRemoteFiltering } from "../useRemoteFiltering";

type FaultEventsTableProps = {
  setAffectedPvins: Dispatch<SetStateAction<AffectedVehicleAttributes[] | undefined>>;
  cluster: Cluster;
  setModalView: Dispatch<SetStateAction<DefinedView | undefined>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

const fields = Object.values(FaultHistoryColumn);

export const FaultEventsTable = ({ setAffectedPvins, cluster, setModalView, setLoading }: FaultEventsTableProps) => {
  const navigate = useNavigate();
  const { customerIdentifier } = useCustomerIdentifier();
  const { globalFilter } = useContext(GlobalFilterContext);

  const {
    pagination: currentPagination,
    filters,
    sorting: sortersInput,
  } = Route.useSearch({
    select: (search) => {
      return search.clusterFaultEventsTable;
    },
  });

  const pagination: Pagination = {
    currentPage: currentPagination?.currentPage || DEFAULT_CURRENT_PAGE,
    pageSize: currentPagination?.pageSize || DEFAULT_PAGE_SIZE,
  };

  const { currentlySearching, setSearchedFilteringOptions, filteringOptions, handleFilterInputChange } =
    useRemoteFiltering({ fields });

  const { data: currentFilteringOptionsData, isFetching: isFetchingFilteringOptions } =
    useFaultHistoryColumnByUserInputQuery(
      {
        input: !isArray(currentlySearching?.content) ? currentlySearching?.content ?? "" : "",
        column: currentlySearching?.field as FaultHistoryColumn,
        columnFilters: filters,
      },
      { enabled: !!currentlySearching?.field }
    );

  useEffect(() => {
    if (!currentlySearching) {
      return;
    }

    const newOptions = (currentFilteringOptionsData?.faultHistoryColumnByUserInput ?? []).map((it) => ({
      value: it,
      label: it,
    }));
    setSearchedFilteringOptions({ field: currentlySearching.field, options: newOptions });
  }, [setSearchedFilteringOptions, currentlySearching, currentFilteringOptionsData]);

  const [selectedFault, setSelectedFault] = useState<FaultsByPvinsAffectedPvinsArgs>();

  const topFaultParams = {
    includeTroubleCode: filters?.some((f) => f.key === FaultHistoryColumn.TroubleCode) || false,
    includeSoftwareVersion: filters?.some((f) => f.key === FaultHistoryColumn.SoftwareVersion) || false,
    includeHardwareVersion: filters?.some((f) => f.key === FaultHistoryColumn.HardwareVersion) || false,
    includeSoftwarePartNumber: filters?.some((f) => f.key === FaultHistoryColumn.SoftwarePartNumber) || false,
    includeHardwarePartNumber: filters?.some((f) => f.key === FaultHistoryColumn.HardwarePartNumber) || false,
  };

  const {
    data: faultsData,
    isLoading: isFaultsLoading,
    isFetching,
  } = useFaultsByPvinsQuery(
    {
      clusterId: cluster?.id,
      filter: globalFilter,
      params: topFaultParams,
      columnFilters: filters,
      pagination,
      sorting: sortersInput as FaultsByPvinsAffectedPvinsSortInput,
    },
    { enabled: !!cluster }
  );

  const { data: pvins } = useFaultsByPvinsAffectedPvinsQuery(
    {
      clusterId: cluster?.id,
      filter: globalFilter,
      params: selectedFault ?? { faultCode: "", ecu: "" },
    },
    { enabled: !!cluster && !!selectedFault }
  );

  useEffect(() => {
    if (pvins) {
      setLoading(false);
      setSelectedFault(undefined);
      setAffectedPvins(pvins.filteredQuery.FaultsByPvinsAffectedPvins);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pvins]);

  const handleRowClick = (row: FaultStat) => {
    setLoading(true);
    setModalView("vehiclesAffected");
    setSelectedFault({
      faultCode: row.faultCode,
      ecu: row.ecu,
      troubleCode: row.troubleCode,
      softwarePartNumber: row.softwarePartNumber,
      softwareVersion: row.softwareVersion,
      hardwarePartNumber: row.hardwarePartNumber,
      hardwareVersion: row.hardwareVersion,
    });
  };

  const buildFaultAnalysisSearch = (row: FaultStat): FaultEventAnalysisSearchFilter => {
    return {
      eventFilter: {
        ecu: row.ecu,
        softwareVersion: topFaultParams.includeSoftwareVersion ? row.softwareVersion?.toString() : undefined,
        hardwareVersion: topFaultParams.includeHardwareVersion ? row.hardwareVersion?.toString() : undefined,
        softwarePartNumber: topFaultParams.includeSoftwarePartNumber ? row.softwarePartNumber?.toString() : undefined,
        hardwarePartNumber: topFaultParams.includeHardwarePartNumber ? row.hardwarePartNumber?.toString() : undefined,
        troubleCode: topFaultParams.includeTroubleCode ? row.troubleCode?.toString() : undefined,
        clusterId: cluster?.id,
      },
    };
  };

  const getFilteringOptions = (fieldName: FaultHistoryColumn) => {
    const availableOptions = filteringOptions[fieldName] as { value: string; label: string }[];
    const previouslyUnselectedValues = filters?.find((filter) => filter.key === fieldName);
    const previouslyUnselectedOptions = (previouslyUnselectedValues?.group ?? []).map((value) => ({
      value: value || "",
      label: value || "",
    }));
    const options = [...previouslyUnselectedOptions, ...availableOptions].sort((a, b) => compareText(a.label, b.label));
    const uniqueOptions = uniqBy(options, "value");
    return uniqueOptions;
  };

  const hiddenColumnAttributes = (columnKey: FaultHistoryColumn) => {
    if (!filters?.some((f) => f.key === columnKey)) {
      return {
        responsive: [],
      };
    }
    return {};
  };

  const getColumns = (): ColumnTypeWithFiltering<FaultStat>[] => {
    return [
      {
        key: "ecu",
        dataIndex: "ecu",
        title: columnFieldToLabel(FaultHistoryColumn.Ecu),
        sorter: true,
        render: (data: string) => data ?? "-",
        filterProps: {
          includeNulls: true,
          criteria: GroupFilterCriteria.Incl,
          type: FilterType.Group,
          label: columnFieldToLabel(FaultHistoryColumn.Ecu),
          isLoading: isFetchingFilteringOptions,
          columnKey: FaultHistoryColumn.Ecu,
          options: getFilteringOptions(FaultHistoryColumn.Ecu),
          onSearchChange: (value: string) => handleFilterInputChange(FaultHistoryColumn.Ecu, value),
        },
      },
      {
        key: "softwareVersion",
        dataIndex: "softwareVersion",
        title: columnFieldToLabel(FaultHistoryColumn.SoftwareVersion),
        sorter: true,
        render: (data: string) => data ?? "-",
        ...hiddenColumnAttributes(FaultHistoryColumn.SoftwareVersion),
        filterProps: {
          includeNulls: true,
          criteria: GroupFilterCriteria.Incl,
          type: FilterType.Group,
          label: columnFieldToLabel(FaultHistoryColumn.SoftwareVersion),
          isLoading: isFetchingFilteringOptions,
          columnKey: FaultHistoryColumn.SoftwareVersion,
          options: getFilteringOptions(FaultHistoryColumn.SoftwareVersion),
          onSearchChange: (value: string) => handleFilterInputChange(FaultHistoryColumn.SoftwareVersion, value),
        },
      },
      {
        key: "hardwareVersion",
        dataIndex: "hardwareVersion",
        title: columnFieldToLabel(FaultHistoryColumn.HardwareVersion),
        sorter: true,
        render: (data: string) => data ?? "-",
        ...hiddenColumnAttributes(FaultHistoryColumn.HardwareVersion),
        filterProps: {
          includeNulls: true,
          criteria: GroupFilterCriteria.Incl,
          type: FilterType.Group,
          label: columnFieldToLabel(FaultHistoryColumn.HardwareVersion),
          isLoading: isFetchingFilteringOptions,
          columnKey: FaultHistoryColumn.HardwareVersion,
          options: getFilteringOptions(FaultHistoryColumn.HardwareVersion),
          onSearchChange: (value: string) => handleFilterInputChange(FaultHistoryColumn.HardwareVersion, value),
        },
      },
      {
        key: "softwarePartNumber",
        dataIndex: "softwarePartNumber",
        title: columnFieldToLabel(FaultHistoryColumn.SoftwarePartNumber),
        sorter: true,
        render: (data: string) => data ?? "-",
        ...hiddenColumnAttributes(FaultHistoryColumn.SoftwarePartNumber),
        filterProps: {
          includeNulls: true,
          criteria: GroupFilterCriteria.Incl,
          type: FilterType.Group,
          label: columnFieldToLabel(FaultHistoryColumn.SoftwarePartNumber),
          isLoading: isFetchingFilteringOptions,
          columnKey: FaultHistoryColumn.SoftwarePartNumber,
          options: getFilteringOptions(FaultHistoryColumn.SoftwarePartNumber),
          onSearchChange: (value: string) => handleFilterInputChange(FaultHistoryColumn.SoftwarePartNumber, value),
        },
      },
      {
        key: "hardwarePartNumber",
        dataIndex: "hardwarePartNumber",
        title: columnFieldToLabel(FaultHistoryColumn.HardwarePartNumber),
        sorter: true,
        ...hiddenColumnAttributes(FaultHistoryColumn.HardwarePartNumber),
        render: (data: string) => data ?? "-",
        filterProps: {
          includeNulls: true,
          criteria: GroupFilterCriteria.Incl,
          type: FilterType.Group,
          label: columnFieldToLabel(FaultHistoryColumn.HardwarePartNumber),
          isLoading: isFetchingFilteringOptions,
          columnKey: FaultHistoryColumn.HardwarePartNumber,
          options: getFilteringOptions(FaultHistoryColumn.HardwarePartNumber),
          onSearchChange: (value: string) => handleFilterInputChange(FaultHistoryColumn.HardwarePartNumber, value),
        },
      },
      {
        key: "faultCode",
        dataIndex: "faultCode",
        title: columnFieldToLabel(FaultHistoryColumn.FaultCode),
        sorter: true,
        render: (data: string) => data ?? "-",
        filterProps: {
          includeNulls: true,
          criteria: GroupFilterCriteria.Incl,
          type: FilterType.Group,
          label: columnFieldToLabel(FaultHistoryColumn.FaultCode),
          isLoading: isFetchingFilteringOptions,
          columnKey: FaultHistoryColumn.FaultCode,
          options: getFilteringOptions(FaultHistoryColumn.FaultCode),
          onSearchChange: (value: string) => handleFilterInputChange(FaultHistoryColumn.FaultCode, value),
        },
      },
      {
        key: "troubleCode",
        dataIndex: "troubleCode",
        title: columnFieldToLabel(FaultHistoryColumn.TroubleCode),
        sorter: true,
        render: (data: string) => data ?? "-",
        ...hiddenColumnAttributes(FaultHistoryColumn.TroubleCode),
        filterProps: {
          includeNulls: true,
          criteria: GroupFilterCriteria.Incl,
          type: FilterType.Group,
          label: columnFieldToLabel(FaultHistoryColumn.TroubleCode),
          isLoading: isFetchingFilteringOptions,
          columnKey: FaultHistoryColumn.TroubleCode,
          options: getFilteringOptions(FaultHistoryColumn.TroubleCode),
          onSearchChange: (value: string) => handleFilterInputChange(FaultHistoryColumn.TroubleCode, value),
        },
      },
      {
        key: "troubleCodeDesc",
        dataIndex: "troubleCodeDesc",
        title: "Trouble Code Description",
        render: (_: any, row: FaultStat) =>
          getFaultDescription(customerIdentifier, row.faultCode, row.ecu, row.troubleCode ?? "")?.name ?? "-",
        ...hiddenColumnAttributes(FaultHistoryColumn.TroubleCode),
      },
      { key: "faultCodeAppearances", title: "Occurrences", dataIndex: "faultCodeAppearances", sorter: true },
      {
        key: "vehiclesAffected",
        title: "Vehicles Affected",
        dataIndex: "vehiclesAffected",
        sorter: true,
      },
      {
        key: "actions",
        title: "",
        dataIndex: "",
        render: (_: any, row: FaultStat) => {
          return (
            <TableActions
              menu={[
                {
                  key: "viewInEventAnalysis",
                  label: (
                    <TableActionItem>
                      <Link
                        to="/fault/$faultCodeId"
                        params={{ faultCodeId: row.faultCode }}
                        search={() => ({ ...buildFaultAnalysisSearch(row) })}
                        onClick={(e) => e.stopPropagation()}
                      >
                        View in event analysis
                      </Link>
                    </TableActionItem>
                  ),
                },
                {
                  key: "viewAffectedVehicles",
                  label: (
                    <TableActionItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRowClick(row);
                      }}
                    >
                      View affected vehicles
                    </TableActionItem>
                  ),
                },
              ]}
            />
          );
        },
        width: 75,
      },
    ].filter((c) => !isEmpty(c));
  };

  return (
    <StatefulTable<FaultStat>
      searchKey="clusterFaultEventsTable"
      columns={getColumns()}
      dataSource={faultsData?.filteredQuery.faultsByPvins.result ?? []}
      pagination={{
        total: faultsData?.filteredQuery.faultsByPvins.totalCount,
        pageSize: pagination.pageSize,
        currentPage: pagination.currentPage,
      }}
      loading={isFaultsLoading || isFetching}
      rowKey={(record) => JSON.stringify(record)}
      onRow={(record) => ({
        onClick: () =>
          navigate({
            to: "/fault/$faultCodeId",
            params: { faultCodeId: record.faultCode },
            search: buildFaultAnalysisSearch(record),
          }),
        style: { cursor: "pointer" },
      })}
      filters={filters}
      sorting={sortersInput}
    />
  );
};

function columnFieldToLabel(column: FaultHistoryColumn): string {
  switch (column) {
    case FaultHistoryColumn.Ecu:
      return "ECU";
    case FaultHistoryColumn.FaultCode:
      return "Fault Code";
    case FaultHistoryColumn.TroubleCode:
      return "Trouble Code";
    case FaultHistoryColumn.SoftwareVersion:
      return "Software Version";
    case FaultHistoryColumn.SoftwarePartNumber:
      return "Software Part Number";
    case FaultHistoryColumn.HardwareVersion:
      return "Hardware Version";
    case FaultHistoryColumn.HardwarePartNumber:
      return "Hardware Part Number";
    default:
      return `"${column}"`;
  }
}
