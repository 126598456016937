import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from "@lib/src/table/defaults";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { Tooltip, Typography } from "antd";
import { ColumnType } from "antd/es/table";

import { Pagination, SavedExpression, useGetSavedExpressionByIdQuery, useGetSavedExpressionsQuery } from "@/api";
import StatefulTable from "@/components/tables/StatefulTable";
import EntityCreatorElement from "@/components/ui/EntityCreatorElement";
import { QUERY_SETTINGS } from "@/constants";
import { Route } from "@/routes/pattern.index";
import { requireCurrentCustomerId } from "@/utils/customers";
import { parseDateToStr } from "@/utils/dateUtils";
import { humanFriendlyStatuses } from "@/utils/patterns";

import { PatternListContainer } from "./PatternList.styled";

export const PatternList = () => {
  const queryClient = useQueryClient();

  const tableState = Route.useSearch({ select: (search) => search.patternsTable });

  const currentPagination: Pagination = {
    currentPage: tableState?.pagination?.currentPage || DEFAULT_CURRENT_PAGE,
    pageSize: tableState?.pagination?.pageSize || DEFAULT_PAGE_SIZE,
  };

  const currentSorting = tableState?.sorting;

  const navigate = useNavigate();

  const { data, isFetching } = useGetSavedExpressionsQuery(
    {
      customerId: requireCurrentCustomerId(),
      pagination: currentPagination,
      sorting: currentSorting,
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );

  const total = data?.savedExpressions?.savedExpressions?.pagination?.totalCount;
  const patterns = data?.savedExpressions?.savedExpressions.items as SavedExpression[];

  const invalidatedSavedExpression = (expression: string) =>
    queryClient.invalidateQueries({
      queryKey: useGetSavedExpressionByIdQuery.getKey({
        customerId: requireCurrentCustomerId(),
        expressionId: expression,
      }),
    });

  const onPatternIdClick = (id: string) => {
    invalidatedSavedExpression(id).then(() => navigate({ to: "/pattern/$patternId", params: { patternId: id } }));
  };

  const columns: ColumnType<SavedExpression>[] = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      width: 350,
      sorter: true,
      render: (name: SavedExpression["name"], row: SavedExpression) => {
        if (typeof row === "object" && row !== null && "id" in row) {
          return <Typography.Link onClick={() => onPatternIdClick(row.id)}>{name}</Typography.Link>;
        } else {
          return (
            <Tooltip title="This pattern can't be edited until is Calculating" placement="top">
              <Typography.Text
                style={{
                  cursor: "not-allowed",
                }}
              >
                {name}
              </Typography.Text>
            </Tooltip>
          );
        }
      },
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
      width: 350,
      sorter: true,
      render(description: SavedExpression["description"]) {
        return <span>{description ?? "-"}</span>;
      },
    },
    {
      key: "creatorName",
      title: "Author",
      dataIndex: "creator.name",
      width: 220,
      sorter: true,
      render: (_: unknown, row: SavedExpression) => <EntityCreatorElement creator={row.creator} />,
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      width: 120,
      sorter: true,
      render(status: SavedExpression["status"]) {
        if (status) return <span>{humanFriendlyStatuses(status)} </span>;
        return <span>Unknown</span>;
      },
    },
    {
      key: "created",
      title: "Created",
      dataIndex: "created",
      width: 170,
      sorter: true,
      render: (created: SavedExpression["created"]) => <span>{parseDateToStr(new Date(created), true)}</span>,
    },
  ];

  return (
    <PatternListContainer className="saved-expression-list">
      <StatefulTable
        searchKey="patternsTable"
        dataSource={patterns}
        columns={columns}
        rowKey={(pattern) => pattern.id}
        pagination={{
          total,
          pageSize: currentPagination.pageSize,
          currentPage: currentPagination.currentPage,
        }}
        sorting={currentSorting}
        loading={isFetching}
      />
    </PatternListContainer>
  );
};
