import { Card } from "antd";
import styled from "styled-components";

export const MetricCardStyled = styled(Card)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.defaultWhite};
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    gap: 0.5rem;
  }
  min-width: 200px;
  flex: 1;
  /* Account for the space between cards */
  flex-basis: calc(25% - 0.75rem);
  max-width: calc(50% - 0.75rem);
`;

export const MetricCardTitle = styled.div`
  text-align: center;
  font-size: 1rem;
  color: #5f5f5f;
`;

export const MetricCardValue = styled.div`
  text-align: center;
  font-size: 2.5rem;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MetricCardContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  gap: 1rem;
`;
