import React from "react";

import { ExpressionEvent } from "@/api";

export const EventHeaderExpression: React.FC<{
  expression?: ExpressionEvent;
}> = ({ expression }) => {
  return (
    <>
      <h1 className="heading-normal header-event-label">
        <span>Pattern Event</span>
      </h1>
      <div className="header-event-data">
        <div className="event-info-container">
          <div className="header-data-section">
            <p className="body-small">Name</p>
            <div className="body-medium dark">{expression?.name ?? "-"}</div>
          </div>
        </div>
      </div>
    </>
  );
};
