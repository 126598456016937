import EditIcon from "@amayaIcons/icon-edit.svg";
import { CopyOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { ClusterNonVehicleDetails } from "@/api";
import { StyledButton } from "@/components/ui/StyledButton";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { parseDateToStr } from "@/utils/dateUtils";

import {
  ClusterViewHeaderRight,
  ClusterViewHeaderWrapper,
  ClusterViewTitleWrapper,
  FieldDiv,
} from "./ClusterView.styled";

type ClusterViewHeaderHeader = {
  clusterNonVehicleData: ClusterNonVehicleDetails;
  handleCopyCluster: () => void;
  handleEditCluster: () => void;
  isLoading: boolean;
};

export const ClusterViewHeader = ({
  clusterNonVehicleData,
  handleCopyCluster,
  handleEditCluster,
  isLoading,
}: ClusterViewHeaderHeader) => {
  const className = isLoading ? "cluster-header-loading" : "";
  return (
    <ClusterViewHeaderWrapper className={className}>
      <BasicWidget
        style={{ borderBottom: "0px" }}
        title={
          <ClusterViewTitleWrapper>
            <h1 className="heading-normal header-event-label">
              <span>{clusterNonVehicleData.name}</span>
            </h1>
            <ClusterViewHeaderRight>
              <FieldDiv>
                <p className="body-small stat-label">Author</p>
                <div className="body-medium dark">{clusterNonVehicleData?.creator?.name || "-"}</div>
              </FieldDiv>
              <FieldDiv>
                <p className="body-small stat-label">Created</p>
                <div className="body-medium dark">
                  {parseDateToStr(new Date(clusterNonVehicleData.created), false, true)}
                </div>
              </FieldDiv>
              <FieldDiv>
                <Button icon={<CopyOutlined />} onClick={() => handleCopyCluster()}>
                  Copy Cluster
                </Button>
              </FieldDiv>
              <FieldDiv>
                <StyledButton
                  icon={<EditIcon />}
                  onClick={() => handleEditCluster()}
                  disabled={!clusterNonVehicleData.creator?.isMe}
                  title={!clusterNonVehicleData.creator?.isMe ? "You cannot edit this cluster" : undefined}
                >
                  Edit Cluster
                </StyledButton>
              </FieldDiv>
            </ClusterViewHeaderRight>
          </ClusterViewTitleWrapper>
        }
      >
        {clusterNonVehicleData.description && (
          <div style={{ padding: "1rem" }}>{clusterNonVehicleData.description}</div>
        )}
      </BasicWidget>
    </ClusterViewHeaderWrapper>
  );
};
