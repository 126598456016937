import styled from "styled-components";

export const PillStyled = styled.div`
  min-width: max-content;
  background: ${({ theme }) => theme.colors.lightGray};
  opacity: 0.75;
  border-radius: 0.5rem;
  padding: 0.25rem 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  .pill-clear-cta {
    font-size: 0.75rem;
  }
  &.pill-selected {
    background: ${({ theme }) => theme.colors.hoverBlue};
    opacity: 1;
  }
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.hoverBlue};
    opacity: 1;
  }
`;
