import { useQueryClient } from "@tanstack/react-query";
import React, { useRef } from "react";

import {
  Cluster,
  ExpressionEvent,
  useAppendVisitedEventMutation,
  useClusterNameQuery,
  useExpressionEventForEventHeaderQuery,
  useRecentEventsQuery,
} from "@/api";
import { EventFilter, EventType } from "@/api/customerApi";
import { RepairEventNames } from "@/components/event/eventHeader/EventHeaderConstants";
import { QUERY_SETTINGS } from "@/constants";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { requireCurrentCustomerId } from "@/utils/customers";
import { getFaultDescription } from "@/utils/fault/getFaultDescription";

const useSavedVisitedEvents = () => {
  const queryClient = useQueryClient();
  const appendVisitedEventMutation = useAppendVisitedEventMutation();
  const { customerIdentifier } = useCustomerIdentifier();
  const [eventFilter, setEventFilter] = React.useState<EventFilter>();
  const hasMutatedRef = useRef(false);

  const queryParams = new URLSearchParams(window.location.search);

  const clusterId = queryParams!.get("clusterId") || eventFilter?.clusterId;

  let currentEvent: EventType | undefined;

  const { data: dataCluster, isFetching: isFetchingCluster } = useClusterNameQuery(
    { customerId: requireCurrentCustomerId() ?? "", id: clusterId ?? "" },
    {
      staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME,
      enabled: !!clusterId,
    }
  );

  const { data: dataExpression, isFetching: isFetchingExpression } = useExpressionEventForEventHeaderQuery(
    { customerId: requireCurrentCustomerId(), expressionEventId: eventFilter?.expressionEventId ?? "" },
    {
      staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME,
      enabled: currentEvent === EventType.Expression,
    }
  );

  const cluster = dataCluster?.clusters?.clusters?.data?.[0] as Cluster;
  const expression = dataExpression?.expressionEvents?.expressionEvent as ExpressionEvent;

  const getEventTypeHeader = () => {
    if (eventFilter?.expressionEventId) currentEvent = EventType.Expression;
    else if (eventFilter?.repairEventType) currentEvent = EventType.Repair;
    else currentEvent = EventType.Fault;
  };

  const getEventID = () => {
    switch (currentEvent) {
      case EventType.Fault:
        return eventFilter?.fault?.faultCode;
      case EventType.Expression:
        return eventFilter?.expressionEventId;
      case EventType.Repair:
        return eventFilter?.repairEventType;
      default:
        return "";
    }
  };

  const getEventName = () => {
    switch (currentEvent) {
      case EventType.Fault:
        return (
          getFaultDescription(
            customerIdentifier,
            eventFilter?.fault?.faultCode ?? "",
            eventFilter?.fault?.ecu ?? "",
            eventFilter?.fault?.troubleCode ?? ""
          )?.name ?? ""
        );
      case EventType.Expression:
        return expression?.name ?? "";
      case EventType.Repair:
        return RepairEventNames[eventFilter?.repairEventType!] ?? "";
      default:
        return "";
    }
  };

  const getEcuValue = () => {
    switch (currentEvent) {
      case EventType.Fault:
        return eventFilter?.fault?.ecu;
      default:
        return "";
    }
  };

  const eventData: any = React.useMemo(() => {
    if (!eventFilter || isFetchingCluster || isFetchingExpression) return null;
    getEventTypeHeader();
    const eventId = getEventID() ?? "";
    const type = currentEvent;
    const name = getEventName();
    const ecu = getEcuValue();
    return {
      customerId: requireCurrentCustomerId(),
      name,
      eventId,
      type: type as EventType,
      ecu,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventFilter, dataCluster, dataExpression]);

  React.useEffect(() => {
    if (
      !hasMutatedRef.current &&
      eventData &&
      !appendVisitedEventMutation.isLoading &&
      !isFetchingCluster &&
      !isFetchingExpression
    ) {
      appendVisitedEventMutation.mutate(eventData, {
        onSettled: () => queryClient.invalidateQueries({ queryKey: useRecentEventsQuery.getKey({}) }),
      });
      hasMutatedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData]);
  return {
    isLoading: appendVisitedEventMutation.isLoading,
    setEventFilter,
    cluster,
    expression,
  };
};

export { useSavedVisitedEvents };
