import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import React from "react";

type ActionElement = {
  label: React.ReactNode;
  key: string;
};

type TableActionsProps = {
  menu: ActionElement[];
  actionTitle?: string;
};

const buildActionsMenu = (menu: ActionElement[], actionTitle?: string) => {
  return actionTitle
    ? {
        items: [
          {
            key: "table-actions-group",
            type: "group",
            label: actionTitle,
            children: menu,
          },
        ],
      }
    : {
        items: menu,
      };
};

const TableActions = ({ menu, actionTitle }: TableActionsProps) => {
  const actionsMenu = buildActionsMenu(menu, actionTitle);

  // Avoid rendering if there are no menu items
  if (!menu.length) return;

  return (
    // The stopPropagations seen here are to avoid conflicts with table features such as drag-copy
    <div
      className="actions-cta-container"
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
    >
      <Dropdown menu={actionsMenu} placement="bottomRight" rootClassName="table-actions-menu" arrow={true}>
        <Button
          size="small"
          type="text"
          className="actions-cta"
          onClick={(e) => e.stopPropagation()}
          icon={<MoreOutlined />}
        />
      </Dropdown>
    </div>
  );
};

export default TableActions;
