import { useContext } from "react";

import { EventFilter, useGetEventStatsQuery } from "@/api/customerApi";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { formatNumber } from "@/utils/numberUtils";
import { toPercent } from "@/utils/toPercent";

import MetricCard from "../metricCard/MetricCard";
import MetricCardContainer from "../metricCard/metricCardContainer";

type EventStatsProps = {
  event: EventFilter;
  isUnavailable?: boolean;
};

export default function EventStats({ event, isUnavailable = false }: EventStatsProps) {
  const { globalFilter } = useContext(GlobalFilterContext);
  const { data, isLoading: isAllTimeDataLoading } = useGetEventStatsQuery(
    { eventFilter: event, filter: globalFilter },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME, enabled: !isUnavailable }
  );
  const isLoading = isUnavailable ? false : isAllTimeDataLoading;

  const totalOccurrences = isUnavailable ? "-" : formatNumber(data?.filteredQuery?.eventStats.totalOccurrences);
  const totalUniqueVehicles = isUnavailable ? "-" : formatNumber(data?.filteredQuery?.eventStats.uniqueVehicles);

  return (
    <MetricCardContainer>
      <MetricCard title="Event Occurrences" value={totalOccurrences} isLoading={isLoading} />
      <MetricCard title="Unique Vehicles" value={totalUniqueVehicles} isLoading={isLoading} />
      <MetricCard
        title="Partial Repair Correlation"
        value={isUnavailable ? "-" : toPercent(data?.filteredQuery?.eventStats.partialRepairCorrelation, 0)}
        isLoading={isLoading}
      />
      <MetricCard
        title="Full Repair Correlation"
        value={isUnavailable ? "-" : toPercent(data?.filteredQuery?.eventStats.fullRepairCorrelation, 0)}
        isLoading={isLoading}
      />
    </MetricCardContainer>
  );
}
