import styled from "styled-components";

export const EventHeaderContainer = styled.div`
  padding: 0 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  .header-event-label {
    padding: 1.5rem 1.5rem 1.5rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5rem;
  }
  .header-event-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: 1.5rem;
    .event-info-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .header-data-section {
      padding: 0 1rem;
      border-left: 1px solid #dedede;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 0.25rem;
    }
  }
`;
